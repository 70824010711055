import axios from "axios";
import { setCachedLanguageCodeToIndexedDB } from "../settings/voiceSettings";

const baseUrl = "https://translation.googleapis.com";

const languageApi = axios.create({
  baseURL: baseUrl,
});

export async function detactLanguageCode(text: string): Promise<string> {
  try {
    const response = await languageApi.get("/language/translate/v2/detect", {
      params: {
        key: process.env.REACT_APP_GOOGLE_API_KEY,
        q: text,
      },
    });
    const detections = response.data.data.detections;
    if (detections === undefined || detections.length === 0) {
      return Promise.reject(new Error("Can't detact language."));
    }
    let languageCode = detections[0][0].language as string;
    languageCode = languageCode.substring(0, 2);
    if (languageCode === undefined) {
      return Promise.reject(new Error("Unknown error."));
    }
    setCachedLanguageCodeToIndexedDB(text, languageCode);
    return languageCode;
  } catch (error) {
    return Promise.reject(error);
  }
}
