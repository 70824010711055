import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginScreen from "./features/auth/LoginScreen";
import MainScreen from "./features/common/MainScreen";
import EmailLoginScreen from "./features/auth/EmailLoginScreen";
import ResetPasswordScreen from "./features/auth/ResetPasswordScreen";
import EmailSignUpScreen from "./features/auth/EmailSignUpScree";
import EmailSignUpAuthScreen from "./features/auth/EmailSignUpAuthScreen";
import { createGlobalStyle } from "styled-components";
import MaintenanceScreen from "./features/common/MaintenanceScreen";
import SlotPurchaseScreen from "./features/purchase/SlotPurchaseScreen";
import AccountSettingScreen from "./features/auth/AccountSettingScreen";
import LearningScreen from "./features/learning/LearningScreen";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: var(--white-color);
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainScreen />} />
          <Route path="/maintenance" element={<MaintenanceScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/login/email" element={<EmailLoginScreen />} />
          <Route
            path="/login/reset-password"
            element={<ResetPasswordScreen />}
          />
          <Route path="/login/sign-up" element={<EmailSignUpScreen />} />
          <Route
            path="/login/sign-up/auth"
            element={<EmailSignUpAuthScreen />}
          />
          <Route path="/slot-purchase" element={<SlotPurchaseScreen />} />
          <Route path="/account-settings" element={<AccountSettingScreen />} />
          <Route path="/learning" element={<LearningScreen />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
