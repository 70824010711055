import Term from "../term/Term";

export default class LearningResult {
  terms: Term[];
  historyId?: string;
  isLastCards: boolean = true;

  constructor(terms: Term[], historyId?: string) {
    this.terms = terms;
    this.historyId = historyId;
  }
}
