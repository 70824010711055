import classNames from "classnames";
import Group from "./Group";
import styles from "./GroupSelectCell.module.css";
import { useTranslation } from "react-i18next";
import { convertGroupString } from "./groups";

interface GroupSelectCellProps {
  group: Group;
  handleOnClickCell: (group: Group) => void;
  handleOnClickMoreButton: (
    e: React.MouseEvent<HTMLDivElement>,
    group: Group
  ) => void;
}

export default function GroupSelectCell(props: GroupSelectCellProps) {
  const { t } = useTranslation();

  const containerClass = classNames(styles.container, {
    [styles.selectedContainer]: props.group.selected === true,
    [styles.readyContainer]: props.group.selected === false,
  });

  const groupnameClass = classNames(styles.groupnameText, {
    [styles.selectedGroupnameText]: props.group.selected === true,
    [styles.readyGroupnameText]: props.group.selected === false,
  });

  const termCountClass = classNames(styles.termCountText, {
    [styles.selectedTermCountText]: props.group.selected === true,
    [styles.readyTermCountText]: props.group.selected === false,
  });

  const percentageClass = classNames(styles.learningPercentangeText, {
    [styles.selectedPercentageText]: props.group.selected === true,
    [styles.readyPercentageText]: props.group.selected === false,
  });

  function handleOnClickMoreButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (
      props.group.title !== "" &&
      props.group.title !== "all" &&
      props.group.title !== "no_group"
    ) {
      props.handleOnClickMoreButton(e, props.group);
    }
  }

  return (
    <>
      <div
        className={containerClass}
        onClick={() => props.handleOnClickCell(props.group)}
      >
        <div className={styles.topContainer}>
          <div />
          <div
            className={styles.moreButtonContainer}
            onClick={handleOnClickMoreButton}
          >
            {props.group.title !== "" &&
            props.group.title !== "all" &&
            props.group.title !== "no_group" ? (
              <img
                className={styles.moreButtonImage}
                src={"iconImgAllMore.png"}
              />
            ) : (
              <div className={styles.moreButtonEmptyContainer}></div>
            )}
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.leftContainer}>
            <p className={groupnameClass}>
              {convertGroupString(props.group.title)}
            </p>
            <p className={termCountClass}>
              {t("GroupSelectTermCount", { termCount: props.group.termCount })}
            </p>
          </div>
          <div className={styles.rightContainer}>
            <p className={percentageClass}>
              {t("GroupLearningPercentage", {
                percentage: props.group.learningPercentange,
              })}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
