import { CancelRounded, CheckCircleRounded } from "@mui/icons-material";
import Term, { getGroupString, getUpdatedLevelString } from "../term/Term";
import styles from "./LearningResultCell.module.css";
import { QuestionType } from "../settings/QuestionType";
import TermLevelButton from "../term/TermLevelButton";

interface LearningResultCellProps {
  term: Term;
  handleOnClickLevelButton: (term: Term) => void;
  handleOnClickMoreButton: (
    event: React.MouseEvent<HTMLDivElement>,
    term: Term
  ) => void;
  config: {
    firstShow: QuestionType;
  };
}

export default function LearningResultCell(props: LearningResultCellProps) {
  const firstText =
    props.config.firstShow === QuestionType.termFirst
      ? props.term.term
      : props.term.definition;
  const secondText =
    props.config.firstShow === QuestionType.termFirst
      ? props.term.definition
      : props.term.term;

  function handleOnClickLevelButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    props.handleOnClickLevelButton(props.term);
  }

  function handleOnClickMoreButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    props.handleOnClickMoreButton(e, props.term);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.isCorrectAnswerForLearningResultContainer}>
          {props.term.isCorrectAnswer === true ? (
            <CheckCircleRounded
              fontSize={"large"}
              sx={{
                color: "var(--level-three-color)",
              }}
            />
          ) : (
            <CancelRounded
              fontSize={"large"}
              sx={{
                color: "var(--level-two-color)",
              }}
            />
          )}
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.topContainer}>
            <div className={styles.topLeftContainer}>
              <TermLevelButton
                currentLevel={props.term.level}
                handleOnClick={handleOnClickLevelButton}
              />
              <p className={styles.updatedLevelText}>
                {getUpdatedLevelString(props.term.updatedLevel)}
              </p>
            </div>
            <div
              className={styles.moreButtonContainer}
              onClick={handleOnClickMoreButton}
            >
              <img
                className={styles.moreButtonImage}
                src="iconImgAllMore.png"
              />
            </div>
          </div>
          <div className={styles.termContainer}>
            <p className={styles.termText}>{firstText}</p>
            <p className={styles.definitionText}>{secondText}</p>
            {props.term.memo.length !== 0 && (
              <p className={styles.memoText}>{props.term.memo}</p>
            )}
            {props.term.pronunciation.length !== 0 && (
              <p className={styles.memoText}>{props.term.memo}</p>
            )}
          </div>
          <div className={styles.groupContainer}>
            <p className={styles.groupText}>
              {getGroupString(props.term.group)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
