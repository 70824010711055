import classNames from "classnames";
import { getLevelString } from "./Term";
import { TermLevel } from "./TermLevel";
import styles from "./TermLevelButton.module.css";

interface TermLevelButtonProps {
  currentLevel: TermLevel;
  handleOnClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export default function TermLevelButton(props: TermLevelButtonProps) {
  const buttonStyle = classNames(styles.button, {
    [styles.levelOneStyle]: props.currentLevel === TermLevel.difficult,
    [styles.levelTwoStyle]: props.currentLevel === TermLevel.familiar,
    [styles.levelThreeStyle]: props.currentLevel === TermLevel.perfect,
  });
  return (
    <>
      <div className={buttonStyle} onClick={props.handleOnClick}>
        <p className={styles.levelText}>{getLevelString(props.currentLevel)}</p>
      </div>
    </>
  );
}
