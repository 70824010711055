import styles from "./DropDownMenu.module.css";

interface DropDownMenuProps {
  value: string;
  handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function DropDownMenu(props: DropDownMenuProps) {
  return (
    <>
      <div className={styles.container} onClick={props.handleOnClick}>
        <p className={styles.valueText}>{props.value}</p>
        <img className={styles.rightArrowImage} src="iconImgAllBack.png" />
      </div>
    </>
  );
}
