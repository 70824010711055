import Term from "./Term";
import TermCell from "./TermCell";
import { QuestionType } from "../settings/QuestionType";
import { PronunciationPosition } from "../settings/PronunciationPosition";
import { Virtuoso } from "react-virtuoso";
import LearningResultCell from "../learning/LearningResultCell";

interface TermListGridProps {
  terms: Term[];
  handleOnClick: (term: Term, isSelectionMode: boolean) => void;
  handleOnClickLevelButton: (term: Term) => void;
  handleOnClickMoreButton: (
    event: React.MouseEvent<HTMLDivElement>,
    term: Term
  ) => void;
  config: {
    style: "list" | "learningResult";
    firstShow: QuestionType;
    pronunciationPosition: PronunciationPosition;
    isSelectionMode: boolean;
    isShowDetailMode?: boolean;
    hideMoreButton?: boolean;
  };
}

export default function TermListGrid(props: TermListGridProps) {
  if (props.config.style === "list") {
    return (
      <Virtuoso
        useWindowScroll
        totalCount={props.terms.length}
        itemContent={(index) => (
          <TermCell
            key={index}
            term={props.terms[index]}
            handleOnClick={() =>
              props.handleOnClick(
                props.terms[index],
                props.config.isSelectionMode
              )
            }
            handleOnClickLevelButton={() =>
              props.handleOnClickLevelButton(props.terms[index])
            }
            handleOnClickMoreButton={(event) =>
              props.handleOnClickMoreButton(event, props.terms[index])
            }
            config={props.config}
          />
        )}
      />
    );
  } else {
    return (
      <Virtuoso
        useWindowScroll
        totalCount={props.terms.length}
        itemContent={(index) => (
          <LearningResultCell
            key={index}
            term={props.terms[index]}
            config={{
              firstShow: props.config.firstShow,
            }}
            handleOnClickMoreButton={(event) =>
              props.handleOnClickMoreButton(event, props.terms[index])
            }
            handleOnClickLevelButton={() =>
              props.handleOnClickLevelButton(props.terms[index])
            }
          />
        )}
      />
    );
  }
}
