import Language from "../language/Language";
import { VoiceGender } from "../voice/voice";

const recentClickedLanguagesKey = "RecentClickedLanguagesKey";
const voiceGenderkey = "VoiceGenderKey";

export function initializeVoiceSettings() {
  deleteVoiceDatabase();
}

export function getRecentClickedLanguagesFromLocalStroage(): Language[] {
  const items = localStorage.getItem(recentClickedLanguagesKey);
  return items ? JSON.parse(items) : [];
}

export function setRecentClickedLanguagesToLocalStorage(codes: Language[]) {
  localStorage.setItem(recentClickedLanguagesKey, JSON.stringify(codes));
}

export function getVoiceGenderFromLocalStroage(): VoiceGender {
  const gender = localStorage.getItem(voiceGenderkey) as VoiceGender | null;
  if (gender === null || !(gender in VoiceGender)) {
    setVoiceGenderToLocalStroage(VoiceGender.female);
    return VoiceGender.female;
  } else {
    return gender as VoiceGender;
  }
}

export function setVoiceGenderToLocalStroage(gender: VoiceGender) {
  localStorage.setItem(voiceGenderkey, gender);
}

const DB_NAME = "voiceCacheDB";
const STORE_NAME = "voices";
const DB_VERSION = 2;

export async function setCachedVoiceToIndexedDB(
  text: string,
  languageCode: string,
  file: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    };

    request.onsuccess = function () {
      const db = request.result;
      const transaction = db.transaction(STORE_NAME, "readwrite");
      const store = transaction.objectStore(STORE_NAME);

      const id = `text:${text}_languageCode:${languageCode}`;
      const data = { id, file };

      const putRequest = store.put(data);
      putRequest.onsuccess = function () {
        resolve();
      };
      putRequest.onerror = function () {
        reject(putRequest.error);
      };
    };

    request.onerror = function () {
      reject(request.error);
    };
  });
}

export async function getCachedVoiceFromIndexedDB(
  text: string,
  languageCode: string
): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function (event) {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    };

    request.onsuccess = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        reject(new Error(`Object store ${STORE_NAME} not found`));
        return;
      }

      const transaction = db.transaction(STORE_NAME, "readonly");
      const store = transaction.objectStore(STORE_NAME);

      const id = `text:${text}_languageCode:${languageCode}`;
      const getRequest = store.get(id);

      getRequest.onsuccess = function () {
        if (getRequest.result) {
          resolve(getRequest.result.file);
        } else {
          resolve(null); // 데이터가 없는 경우 null 반환
        }
      };
      getRequest.onerror = function () {
        reject(getRequest.error);
      };
    };

    request.onerror = function () {
      reject(request.error);
    };
  });
}

export async function getCachedLanguageCodeFromIndexedDB(
  text: string
): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function (event) {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    };

    request.onsuccess = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        reject(new Error(`Object store ${STORE_NAME} not found`));
        return;
      }

      const transaction = db.transaction(STORE_NAME, "readonly");
      const store = transaction.objectStore(STORE_NAME);

      const id = `textLanguage:${text}`;
      const getRequest = store.get(id);

      getRequest.onsuccess = function () {
        if (getRequest.result) {
          resolve(getRequest.result.languageCode);
        } else {
          resolve(null); // 데이터가 없는 경우 null 반환
        }
      };
      getRequest.onerror = function () {
        reject(getRequest.error);
      };
    };

    request.onerror = function () {
      reject(request.error);
    };
  });
}

export async function setCachedLanguageCodeToIndexedDB(
  text: string,
  languageCode: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    };

    request.onsuccess = function () {
      const db = request.result;
      const transaction = db.transaction(STORE_NAME, "readwrite");
      const store = transaction.objectStore(STORE_NAME);

      const id = `textLanguage:${text}`;
      const data = { id, languageCode };

      const putRequest = store.put(data);
      putRequest.onsuccess = function () {
        resolve();
      };
      putRequest.onerror = function () {
        reject(putRequest.error);
      };
    };

    request.onerror = function () {
      reject(request.error);
    };
  });
}

export async function deleteVoiceDatabase(): Promise<void> {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(DB_NAME);
    deleteRequest.onsuccess = function () {
      resolve();
    };
    deleteRequest.onerror = function () {
      reject(deleteRequest.error);
    };
  });
}
