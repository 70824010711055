import {
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../configurations/firebaseConfig";
import i18n from "../../i18n";
import { logOut } from "./authSlice";
import { initializeTermListSettings } from "../settings/termListSettings";
import { setLastUpdatedProfileImage } from "../settings/userSettings";
import { initializeLearningSettings } from "../settings/learningSettings";
import { initializeVoiceSettings } from "../settings/voiceSettings";

const currentLanguage = i18n.language;
const onevocaUrl = process.env.REACT_APP_ONEVOCA_APP_URL;

const actionCodeSettings = {
  //url: 'https://www.example.com/finishSignUp?cartId=1234',
  url: onevocaUrl + "/login",
  handleCodeInApp: true,
};

export function getUsername(): string {
  const username = auth.currentUser?.displayName;
  if (username === null || username === undefined) {
    return "Unknown";
  } else {
    return username;
  }
}

export function isLogin(): boolean {
  return auth.currentUser !== null;
}

function resetData() {
  initializeLearningSettings();
  initializeTermListSettings();
  initializeVoiceSettings();
}

export function login() {
  setLastUpdatedProfileImage(Date.now().toString());
}

export async function logout() {
  resetData();
  await auth.signOut();
  logOut();
}

export async function deleteCurrentUser() {
  resetData();
  await auth.currentUser?.delete();
}

export async function getToken(): Promise<string> {
  if (auth.currentUser !== null) {
    return auth.currentUser.getIdToken();
  } else {
    return Promise.reject(new Error("Not found user."));
  }
}

export function signInWithApple(): Promise<UserCredential> {
  const provider = new OAuthProvider("apple.com");
  provider.addScope("email");
  provider.addScope("name");
  provider.setCustomParameters({ locale: currentLanguage });
  return signInWithPopup(auth, provider);
}

export function signInWithGoogle(): Promise<UserCredential> {
  const provider = new GoogleAuthProvider();
  provider.addScope("email");
  return signInWithPopup(auth, provider);
}

export function createUserWithEmail(
  email: string,
  password: string
): Promise<UserCredential> {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function signInWithEmail(
  email: string,
  password: string
): Promise<UserCredential> {
  return signInWithEmailAndPassword(auth, email, password);
}

export async function isAuthEmailLogin(): Promise<boolean> {
  if (auth.currentUser == null) {
    return Promise.reject(new Error("Not found user."));
  } else {
    await auth.currentUser.reload();
    return Promise.resolve(auth.currentUser.emailVerified);
  }
}

export function sendAuthEmail(): Promise<void> {
  if (auth.currentUser === null) {
    return Promise.reject(new Error("Not found current user."));
  } else {
    return sendEmailVerification(auth.currentUser, actionCodeSettings);
  }
}

export async function updateDisplayName(username: string): Promise<void> {
  if (auth.currentUser !== null) {
    return updateProfile(auth.currentUser, {
      displayName: username,
    });
  } else {
    return Promise.reject(new Error("Not found user."));
  }
}
