export enum TermLevel {
  difficult = "0",
  familiar = "1",
  perfect = "2",
}

export function getCurrentLevelExp(level: TermLevel): number {
  switch (level) {
    case TermLevel.difficult:
      return 0;
    case TermLevel.familiar:
      return 51;
    case TermLevel.perfect:
      return 201;
  }
}
