import { useEffect, useState } from "react";

interface ProfileIconProps {
  size: string;
  src: string | undefined;
  onCursorPoint: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface ImageWithFallbackProps {
  style: React.CSSProperties;
  src?: string | null;
  alt: string;
  fallbackSrc: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  style,
  src,
  alt,
  fallbackSrc,
}) => {
  const [imgSrc, setImgSrc] = useState<string>(src || fallbackSrc);

  useEffect(() => {
    setImgSrc(src || fallbackSrc);
  }, [src, fallbackSrc]);

  const handleError = () => {
    setImgSrc(fallbackSrc);
  };
  return <img src={imgSrc} style={style} alt={alt} onError={handleError} />;
};

export default function ProfileIcon(props: ProfileIconProps) {
  return (
    <>
      <div onClick={props.onClick}>
        <ImageWithFallback
          style={{
            width: props.size,
            height: props.size,
            borderRadius: "50%",
            objectFit: "cover",
            cursor: props.onCursorPoint === true ? "pointer" : "default",
          }}
          src={props.src}
          alt="Profile Icon"
          fallbackSrc="imgProfileUserDefaultr.png"
        />
      </div>
    </>
  );
}
