import { VolumeUpOutlined } from "@mui/icons-material";
import styles from "./SpeakButton.module.css";

interface SpeakButtonProps {
  fontSize: "small" | "medium" | "large";
  isPlaying: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export default function SpeakButton(props: SpeakButtonProps) {
  function handleOnClickButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    props.onClick(e);
  }

  return (
    <>
      <div className={styles.container} onClick={handleOnClickButton}>
        <VolumeUpOutlined
          fontSize={props.fontSize}
          sx={{
            color: "var(--main-black-color)",
            opacity: props.isPlaying ? 0.5 : 1, // 투명도 조정
            transition: "opacity 0.3s ease-in-out", // 부드러운 전환
          }}
        />
      </div>
    </>
  );
}
