import { useEffect, useState } from "react";
import {
  deleteTerm as deleteTermFromAPI,
  fetchTerms,
  getTermsCount,
} from "../term/termAPI";
import { useNavigate } from "react-router-dom";
import { logout } from "../auth/AuthManager";
import { ServerStatus, fetchServerStatus } from "./serverStatus";
import { useTranslation } from "react-i18next";
import styles from "./MainScreen.module.css";
import TopNavigation from "./TopNavigation";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../configurations/firebaseConfig";
import TermListScreen from "../term/TermListScreen";
import LoadingScreen from "./LoadingScreen";
import Term, { getNextLevel } from "../term/Term";
import {
  getSelectedGroupsFromLocalStorage,
  getSortTypeFromLocalStorage,
} from "../settings/termListSettings";
import { getRandomNumber } from "./numberManager";
import { Status } from "./Status";
import { TermLevel } from "../term/TermLevel";
import { updateTermLevel as updateTermLevelFromAPI } from "../term/termAPI";
import OnevocaDialog from "./OnevocaDialog";
import TermEditScreen from "../term/TermEditScreen";
import {
  updateTerm as updateTermFromAPI,
  updateTermsGroup as updateTermsGroupFromAPI,
  updateTermsLevel as updateTermsLevelFromAPI,
  deleteTerms as deleteTermsFromAPI,
} from "../term/termAPI";
import { Snackbar } from "@mui/material";
import TermAddScreen from "../term/TermAddScreen";
import BusinessContainer from "./BusinessContainer";
import GroupSelectScreen from "../groups/GroupSelectScreen";
import Group from "../groups/Group";
import BasicMenu from "./BasicMenu";
import AccountMenu from "../auth/AccountMenu";
import { getSlots, updateUser } from "../auth/authAPI";
import {
  setMyTermsCountToLocalStorage,
  setSlotsToLocalStorage,
} from "../settings/userSettings";
import LearningSelectScreen from "../learning/LearningSelectScreen";
import { useAppDispatch } from "../../hook";
import { updateTerms } from "../term/termSlice";
import { useTerms } from "../term/useTerms";
import LeftTabBar from "./leftTabBar";
import useTermEditor from "./useTermEditor";

export default function MainScreen() {
  const { t } = useTranslation();
  const [tab, setTab] = useState("home");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const termsDispatch = useAppDispatch();
  const terms = useTerms();

  const [serverStatus, setServerStatus] = useState<ServerStatus>();
  const [termFetchStatus, setTermFetchStatus] = useState(Status.Ready);
  const [termLevelUpdateStatus, setTermLevelUpdateStatus] = useState(
    Status.Ready
  );

  const [termUpdateStatus, setTermUpdateStatus] = useState(Status.Ready);

  const [termDeleteStatus, setTermDeleteStatus] = useState(Status.Ready);
  const [termsUpdateStatus, setTermsUpdateStatus] = useState(Status.Ready);

  const [termEditSelectedItem, setTermEditSelectedItem] = useState<Term | null>(
    null
  );

  const [termDeleteSelectedItem, setTermDeleteSelectedItem] =
    useState<Term | null>(null);
  const [termsGroupChangeSelected, setTermsGroupChangeSelected] = useState<
    Term[] | null
  >(null);
  const [termsLevelChangeSelected, setTermsLevelChangeSelected] = useState<
    Term[] | null
  >(null);
  const [termsDeleteSelected, setTermsDeleteSelected] = useState<Term[] | null>(
    null
  );

  const [showTermAddScreen, setShowTermAddScreen] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const termEditState = useTermEditor();

  const navigate = useNavigate();

  const selectedLevelButtons = [
    {
      title: t("Difficult"),
      handleOnClose: () => {
        handleOnCloseSelectedLevelMenu(TermLevel.difficult);
      },
    },
    {
      title: t("Familiar"),
      handleOnClose: () => {
        handleOnCloseSelectedLevelMenu(TermLevel.familiar);
      },
    },
    {
      title: t("Perfect"),
      handleOnClose: () => {
        handleOnCloseSelectedLevelMenu(TermLevel.perfect);
      },
    },
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkServerInspect();
      } else {
        logout();
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (termEditSelectedItem !== null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [termEditSelectedItem]);

  useEffect(() => {
    if (serverStatus !== undefined && serverStatus !== null) {
      switch (serverStatus) {
        case ServerStatus.Maintenance:
          navigate("/maintenance");
          break;
        case ServerStatus.Running:
          updateUser();
          updateSlot();
          updateTermsCount();
          fetchData(false);
          break;
      }
    }
  }, [serverStatus]);

  useEffect(() => {
    switch (termFetchStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        break;
    }
  }, [termFetchStatus]);

  useEffect(() => {
    switch (termLevelUpdateStatus) {
      case Status.Ready:
        break;
      case Status.Loading:
        break;
      case Status.Done:
        break;
    }
  }, [termLevelUpdateStatus]);

  useEffect(() => {
    switch (termUpdateStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        setToastMessage(t("TermEditedToastMessage"));
        break;
    }
  }, [termUpdateStatus]);

  useEffect(() => {
    switch (termDeleteStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        break;
    }
  }, [termDeleteStatus]);

  useEffect(() => {
    switch (termsUpdateStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        setToastMessage(t("TermEditedToastMessage"));
        break;
    }
  }, [termsUpdateStatus]);

  useEffect(() => {}, [terms]);

  async function updateSlot() {
    try {
      const slot = await getSlots();
      setSlotsToLocalStorage(slot);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateTermsCount() {
    try {
      const termsCount = await getTermsCount();
      setMyTermsCountToLocalStorage(termsCount.toString());
    } catch (error) {
      console.log(error);
    }
  }

  async function checkServerInspect() {
    setIsLoading(true);
    try {
      setServerStatus(await fetchServerStatus());
      setIsLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
      setIsLoading(false);
    }
  }

  async function fetchData(withShuffle: boolean) {
    const sort = withShuffle ? undefined : getSortTypeFromLocalStorage();
    const seed = withShuffle ? getRandomNumber(1, 999999) : 1;
    try {
      setTermFetchStatus(Status.Loading);
      const newTerms = await fetchTerms(seed, sort);
      const serializedTerms = newTerms.map((term) => Term.toJson(term));
      termsDispatch(updateTerms(serializedTerms));
      setTermFetchStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermFetchStatus(Status.Ready);
    }
  }

  function moveToMain() {
    navigate("/");
  }

  function handleClickUserIcon(e: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(e.currentTarget);
    setShowAccountMenu(true);
  }

  function handleOnClickSlotAddButton() {
    if (auth.currentUser?.providerData[0].email === "suhz23@naver.com") {
      navigate("slot-purchase");
    } else {
      alert(
        "웹 버전 원보카 결제는 현재 준비중입니다. 모바일 앱에서 결제를 진행해주세요!"
      );
    }
  }

  function handleOnClickAccountSettingsButton() {
    navigate("/account-settings");
  }

  async function handleClickLogOutButton() {
    await logout();
  }

  function handleClickTab(tab: string) {
    setTab(tab);
  }

  async function handleOnClickTermLevel(term: Term) {
    setTermLevelUpdateStatus(Status.Loading);
    try {
      const nextLevel = getNextLevel(term.level);
      updateTermLevelFromAPI(term, nextLevel);
      updateTermLevelToList(term, nextLevel);
      setTermLevelUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermLevelUpdateStatus(Status.Ready);
    }
  }

  async function updateTerm(term: Term) {
    setTermUpdateStatus(Status.Loading);
    try {
      await updateTermFromAPI(term);
      updateTermToList(term);
      setTermUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermUpdateStatus(Status.Ready);
    }
  }

  function updateTermToList(term: Term) {
    const termIndex = terms.findIndex((t) => t.id === term.id);
    if (termIndex !== -1) {
      const newTerms = [...terms];
      newTerms[termIndex] = term;
      const serializedTerms = newTerms.map((term) => Term.toJson(term));
      termsDispatch(updateTerms(serializedTerms));
    }
  }

  function updateTermLevelToList(term: Term, toLevel: TermLevel) {
    const termIndex = terms.findIndex((t) => t.id === term.id);
    if (termIndex !== -1) {
      const newTerms = [...terms];
      newTerms[termIndex] = {
        ...newTerms[termIndex],
        level: toLevel,
      };
      const serializedTerms = newTerms.map((term) => Term.toJson(term));
      termsDispatch(updateTerms(serializedTerms));
    }
  }

  function handleOnClickTermEditButton(term: Term) {
    setTermEditSelectedItem(term);
  }

  function handleOnClickTermDeleteButton(term: Term) {
    setTermDeleteSelectedItem(term);
  }

  function handleOnClickTermAddButton() {
    setShowTermAddScreen(true);
  }

  function handleOnClickShuffleButton() {
    fetchData(true);
  }

  function handleOnClickSelectAllButton() {
    updateSelectionsOfTerms(true);
  }

  function handleOnClickCancelSelectionAllButton() {
    updateSelectionsOfTerms(false);
  }

  function handleOnCloseTermAddScreen() {
    setShowTermAddScreen(false);
  }

  function handleOnClickSelectedTermsGroupChangeButton(selectedTerms: Term[]) {
    setTermsGroupChangeSelected(selectedTerms);
  }

  function handleOnClickSelectedTermsLevelChangeButton(
    e: React.MouseEvent<HTMLDivElement>,
    selectedTerms: Term[]
  ) {
    setAnchorEl(e.currentTarget);
    setTermsLevelChangeSelected(selectedTerms);
  }

  function handleOnClickSelectedTermsDeleteButton(selectedTerms: Term[]) {
    setTermsDeleteSelected(selectedTerms);
  }

  async function deleteTermsServer(deleteTerms: Term[]) {
    setTermsUpdateStatus(Status.Loading);
    try {
      await deleteTermsFromAPI(deleteTerms);
      deleteTermsToList(deleteTerms);
      setTermsUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermsUpdateStatus(Status.Ready);
    }
  }

  function deleteTermsToList(deleteTerms: Term[]) {
    const idsToDelete = new Set(deleteTerms.map((term) => term.id));
    const newTerms = terms.filter((term) => !idsToDelete.has(term.id));
    const serializedTerms = newTerms.map((term) => Term.toJson(term));
    termsDispatch(updateTerms(serializedTerms));
  }

  async function deleteTermServer(term: Term) {
    if (term === null) {
      return;
    }

    setTermDeleteStatus(Status.Loading);
    try {
      await deleteTermFromAPI(term);
      deleteTerm(term);
      setTermDeleteStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermDeleteStatus(Status.Ready);
    }
  }

  function deleteTerm(term: Term) {
    const termIndex = terms.findIndex((t) => t.id === term.id);
    const newTerms = [...terms];
    if (termIndex !== -1) {
      newTerms.splice(termIndex, 1);
    }
    const serializedTerms = newTerms.map((term) => Term.toJson(term));
    termsDispatch(updateTerms(serializedTerms));
  }

  function updateSelectionsOfTerms(selection: boolean) {
    const newTerms = terms.map((term) => ({
      ...term,
      selected: selection,
    }));
    const serializedTerms = newTerms.map((term) => Term.toJson(term));
    termsDispatch(updateTerms(serializedTerms));
  }

  async function updateTermsLevel(toTerms: Term[], toLevel: TermLevel) {
    try {
      setTermsUpdateStatus(Status.Loading);
      await updateTermsLevelFromAPI(toTerms, toLevel);
      updateTermsLevelToList(toTerms, toLevel);
      setTermsUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermUpdateStatus(Status.Ready);
    }
  }

  function updateTermsLevelToList(toTerms: Term[], toLevel: TermLevel) {
    const newTerms = terms.map((term) => {
      const matchingTerm = toTerms.find((toTerm) => toTerm.id === term.id);
      if (matchingTerm) {
        return {
          ...term,
          level: toLevel,
        };
      }
      return term;
    });
    const serializedTerms = newTerms.map((term) => Term.toJson(term));
    termsDispatch(updateTerms(serializedTerms));
  }

  async function updateTermsGroup(toTerms: Term[], toGroup: Group) {
    try {
      setTermsUpdateStatus(Status.Loading);
      await updateTermsGroupFromAPI(toTerms, toGroup);
      updateTermsGroupToList(toTerms, toGroup);
      setTermsUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermsUpdateStatus(Status.Ready);
    }
  }

  function updateTermsGroupToList(toTerms: Term[], toGroup: Group) {
    const newTerms = terms.map((term) => {
      const matchingTerm = toTerms.find((toTerm) => toTerm.id === term.id);
      if (matchingTerm) {
        return {
          ...term,
          group: toGroup.title,
        };
      }
      return term;
    });
    const serializedTerms = newTerms.map((term) => Term.toJson(term));
    termsDispatch(updateTerms(serializedTerms));
  }

  function handleOnCloseSelectedLevelMenu(level: TermLevel) {
    if (termsLevelChangeSelected !== null) {
      updateTermsLevel(termsLevelChangeSelected, level);
    }
    setTermsLevelChangeSelected(null);
  }

  function handleOnClickContactUsButton() {
    const email = "zak1ller.kr@gmail.com";
    const link = `mailto:${email}`;
    window.location.href = link;
  }

  function updateSelectedTab(selectedTab: "list" | "learn") {
    switch (selectedTab) {
      case "list":
        setTab("home");
        break;
      case "learn":
        setTab("learning");
        break;
    }
    setShowLeftBar(false);
  }

  return (
    <div className={styles.mainStack}>
      <div className={styles.mainScreen}>
        <TopNavigation
          showTab={true}
          showUserIcon={true}
          handleClickToggleLeftBarButton={() => {
            setShowLeftBar(!showLeftBar);
          }}
          handleClickOnevocaLogo={() => moveToMain()}
          handleClickUserProfileIcon={handleClickUserIcon}
          handleClickLogOutButton={() => handleClickLogOutButton()}
          handleClickTab={handleClickTab}
        />
        <div className={styles.container}>
          <div className={styles.contentsContainer}>
            <div className={styles.sectionContainer}>
              {tab === "home" && (
                <TermListScreen
                  isLoading={isLoading}
                  handleOnClickLevelButton={handleOnClickTermLevel}
                  handleOnClickTermEditButton={handleOnClickTermEditButton}
                  handleOnClickTermDeleteButton={handleOnClickTermDeleteButton}
                  handleOnClickTermAddButton={handleOnClickTermAddButton}
                  handleOnClickShuffleButton={handleOnClickShuffleButton}
                  handleOnClickSelectAllButton={() =>
                    handleOnClickSelectAllButton()
                  }
                  handleOnClickCancelSelectionAllButton={() =>
                    handleOnClickCancelSelectionAllButton()
                  }
                  handleOnClickSelectedTermsGroupChangeButton={
                    handleOnClickSelectedTermsGroupChangeButton
                  }
                  handleOnClickSelectedTermsLevelChangeButton={
                    handleOnClickSelectedTermsLevelChangeButton
                  }
                  handleOnClickSelectedTermsDeleteButton={
                    handleOnClickSelectedTermsDeleteButton
                  }
                />
              )}
              {tab === "learning" && <LearningSelectScreen />}
            </div>
            <BusinessContainer />
          </div>
        </div>
      </div>
      {isLoading === true && <LoadingScreen />}
      <TermAddScreen
        open={showTermAddScreen === true}
        selectedGroup={
          getSelectedGroupsFromLocalStorage().length === 1
            ? getSelectedGroupsFromLocalStorage()[0]
            : undefined
        }
        onSaved={() => {
          setToastMessage(t("TermRegisrered"));
          fetchData(false);
        }}
        onClose={() => handleOnCloseTermAddScreen()}
      />
      <TermEditScreen
        open={termEditSelectedItem !== null}
        term={termEditSelectedItem!}
        handleOnSave={(term: Term) => updateTerm(term)}
        handleOnClose={() => {
          setTermEditSelectedItem(null);
        }}
      />
      <GroupSelectScreen
        open={termsGroupChangeSelected !== null}
        selectStyle="single"
        showAllGroup={false}
        showNoGroup={false}
        showGroupAddButton={true}
        handleOnSelectGroups={(groups: Group[]) => {
          const group = groups[0] ?? null;
          if (group !== null && termsGroupChangeSelected !== null) {
            updateTermsGroup(termsGroupChangeSelected, group);
          }
        }}
        handleOnClose={() => {
          setTermsGroupChangeSelected(null);
        }}
      />
      <LeftTabBar
        open={showLeftBar === true}
        onChangeSelectedTab={updateSelectedTab}
        clickEmptyContainer={() => {
          setShowLeftBar(false);
        }}
        clickToggleLeftBarButton={() => {
          setShowLeftBar(false);
        }}
      />
      <BasicMenu
        isOpen={termsLevelChangeSelected !== null}
        anchorEl={anchorEl}
        buttons={selectedLevelButtons}
        handleOnClose={() => {
          setTermsLevelChangeSelected(null);
        }}
      />
      <OnevocaDialog
        open={termsDeleteSelected !== null}
        title={null}
        message={t("areyousuremesssage")}
        buttons={[
          {
            title: t("Delete"),
            style: "solid",
            onClick: () => {
              if (termsDeleteSelected !== null) {
                const deleteTerms = [...termsDeleteSelected];
                setTermsDeleteSelected(null);
                deleteTermsServer(deleteTerms);
              }
            },
          },
          {
            title: t("Cancel"),
            style: "normal",
            onClick: () => {
              setTermsDeleteSelected(null);
            },
          },
        ]}
      />
      <AccountMenu
        open={showAccountMenu === true}
        anchorEl={anchorEl}
        onClickSlotAddButton={() => handleOnClickSlotAddButton()}
        onClickSettingsButton={() => handleOnClickAccountSettingsButton()}
        onClickLogOutButton={() => handleClickLogOutButton()}
        onClickContactUsButton={() => handleOnClickContactUsButton()}
        onClose={() => {
          setShowAccountMenu(false);
        }}
      />
      <OnevocaDialog
        open={termDeleteSelectedItem !== null}
        title={null}
        message={t("areyousuremesssage")}
        buttons={[
          {
            title: t("Delete"),
            style: "solid",
            onClick: () => {
              const term = termDeleteSelectedItem;
              if (term !== null) {
                setTermDeleteSelectedItem(null);
                deleteTermServer(term);
              }
            },
          },
          {
            title: t("Cancel"),
            style: "normal",
            onClick: () => {
              setTermDeleteSelectedItem(null);
            },
          },
        ]}
      />
      <Snackbar
        open={toastMessage !== null}
        autoHideDuration={3000}
        onClose={() => {
          setToastMessage(null);
        }}
        message={toastMessage}
      />
    </div>
  );
}
