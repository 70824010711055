import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "onevoca.firebaseapp.com",
  databaseURL: "https://onevoca.firebaseio.com",
  projectId: "onevoca",
  storageBucket: "onevoca.appspot.com",
  messagingSenderId: "958843371657",
  appId: "1:958843371657:web:25038c58f5c84ee6f90658",
  measurementId: "G-73K1TBYBLW",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
