import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import styles from "./OnevocaCheckBoxContainer.module.css";

interface OnevocaCheckBoxItem {
  title: string;
  checked: boolean;
  onChangeValue: (checked: boolean) => void;
}

interface OnevocaCheckBoxContainerProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  items: OnevocaCheckBoxItem[];
  needCheckOneOrMore: boolean;
  onClose: () => void;
}

export default function OnevocaCheckBoxContainer(
  props: OnevocaCheckBoxContainerProps
) {
  const checkedItemsCount = props.items.filter((item) => item.checked).length;

  function handleOnClickContainer() {
    props.onClose();
  }

  function handleOnClickContentContainer(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  if (props.open === false || props.anchorEl === null) {
    document.body.style.overflow = "auto";
    return null;
  } else {
    document.body.style.overflow = "hidden";
  }

  const { top, right, height } = props.anchorEl.getBoundingClientRect();

  const contentContainerStyles: React.CSSProperties = {
    position: "absolute",
    top: top + height + 8,
    right: window.innerWidth - right,
    backgroundColor: "white",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    padding: "8px",
    zIndex: 1000,
    borderRadius: "8px",
  };

  return (
    <>
      <div
        className={styles.container}
        onClick={() => handleOnClickContainer()}
      >
        <div
          style={contentContainerStyles}
          onClick={handleOnClickContentContainer}
        >
          <div className={styles.checkBoxContainer}>
            <FormGroup>
              {props.items.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={item.checked}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const isChecked = e.target.checked;
                        if (
                          !isChecked &&
                          props.needCheckOneOrMore &&
                          checkedItemsCount <= 1
                        ) {
                          return;
                        }
                        item.onChangeValue(isChecked);
                      }}
                    />
                  }
                  label={item.title}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </div>
    </>
  );
}
