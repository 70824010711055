import i18n from "i18next";
import Group from "./Group";

export function getExistGroup(groups: Group[], fromGroups: Group[]): Group[] {
  return groups.filter((group) =>
    fromGroups.some((fromGroup) => fromGroup.title === group.title)
  );
}

export function convertGroupString(group: string): string {
  if (group === "all") {
    return i18n.t("AllGroups");
  } else if (group === "no_group") {
    return i18n.t("ThereIsNoGroup");
  } else {
    return group;
  }
}

export function makeSelectedGroupsString(selectedGroups: Group[]): string {
  if (selectedGroups.length === 0) {
    return i18n.t("AllGroups");
  } else if (selectedGroups.length === 1) {
    const selectedGroup = selectedGroups[0];
    if (selectedGroup.title === "") {
      return i18n.t("ThereIsNoGroup");
    } else {
      return selectedGroup.title;
    }
  } else {
    let value = "";
    selectedGroups.forEach((group, index) => {
      if (group.title === "" || group.title === "no_group") {
        if (index === 0) {
          value += i18n.t("ThereIsNoGroup");
        } else {
          value += `, ${i18n.t("ThereIsNoGroup")}`;
        }
      } else {
        if (index === 0) {
          value += group.title;
        } else {
          value += `, ${group.title}`;
        }
      }
    });
    return value;
  }
}
