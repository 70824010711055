import heic2any from "heic2any";

export async function convertFileToJpg(
  file: File,
  filename: string,
  fileSize: number | undefined = undefined
): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    if (file) {
      try {
        let imageFile = file;
        if (file.type === "image/heic") {
          const convertedBlobArray = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.8,
          });

          const convertedBlob = Array.isArray(convertedBlobArray)
            ? convertedBlobArray[0]
            : convertedBlobArray;

          imageFile = new File([convertedBlob], `${filename}`, {
            type: "image/jpeg",
          });
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target?.result as string;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            if (fileSize !== undefined) {
              const maxSize = 150;
              const scale = Math.min(maxSize / img.width, maxSize / img.height);
              canvas.width = img.width * scale;
              canvas.height = img.height * scale;
            }

            ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
            const jpgDataUrl = canvas.toDataURL("image/jpeg", 0.8); // 이미지 압축률(0.8)을 설정
            resolve(jpgDataUrl); // 데이터 URL을 반환
          };
          img.onerror = (err) => {
            reject(err);
          };
        };
        reader.onerror = (err) => {
          reject(err);
        };
        reader.readAsDataURL(imageFile);
      } catch (error) {
        reject(error);
      }
    } else {
      reject(new Error("파일이 제공되지 않았습니다."));
    }
  });
}
