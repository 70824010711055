import styles from "./EmailSignUpAuthScreen.module.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BigButton from "../common/BigButton";
import { FirebaseError } from "firebase/app";
import { getFirebaseErrorMessage } from "../common/FirebaseErrorConverter";
import { useEffect, useState } from "react";
import { Status } from "../common/Status";
import { useNavigate } from "react-router-dom";
import { isAuthEmailLogin, login, logout, sendAuthEmail } from "./AuthManager";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "./authAPI";
import { logIn } from "./authSlice";
import LoadingScreen from "../common/LoadingScreen";
import { ServerStatus, fetchServerStatus } from "../common/serverStatus";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../configurations/firebaseConfig";

function EmailSignUpAuthScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serverStatus, setServerStatus] = useState<ServerStatus>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { email } = location.state || {};

  const [authResendStatus, setAuthResendStatus] = useState<Status>(
    Status.Ready
  );

  const [emailVeritifiedCheckStatus, setEmailVeritifiedCheckStatus] =
    useState<Status>(Status.Ready);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkServerInspect();
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (serverStatus !== undefined && serverStatus !== null) {
      switch (serverStatus) {
        case ServerStatus.Maintenance:
          navigate("/maintenance");
          break;
        case ServerStatus.Running:
          navigate("/");
          break;
      }
    }
  }, [serverStatus]);

  useEffect(() => {
    switch (authResendStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        alert(t("signup_resend_mail"));
        break;
    }
  }, [authResendStatus]);

  useEffect(() => {
    switch (emailVeritifiedCheckStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        login();
        dispatch(logIn());
    }
  }, [emailVeritifiedCheckStatus]);

  async function checkServerInspect() {
    try {
      setServerStatus(await fetchServerStatus());
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  async function handleClickResendButton() {
    setAuthResendStatus(Status.Loading);
    try {
      await sendAuthEmail();
      setAuthResendStatus(Status.Done);
    } catch (error) {
      if (error instanceof FirebaseError) {
        alert(getFirebaseErrorMessage(error, t));
      } else {
        alert(error);
      }
      setAuthResendStatus(Status.Ready);
    }
  }

  async function handleClickSignUpCompleteButton() {
    setEmailVeritifiedCheckStatus(Status.Loading);
    try {
      await isAuthEmailLogin();
      const isCompletedEmailVerified = await isAuthEmailLogin();
      if (isCompletedEmailVerified === true) {
        await updateUser();
        setEmailVeritifiedCheckStatus(Status.Done);
      } else {
        alert(t("signup_you_have_to_verify"));
        setEmailVeritifiedCheckStatus(Status.Ready);
      }
    } catch (error) {
      alert(error);
      setEmailVeritifiedCheckStatus(Status.Ready);
    }
  }

  return (
    <div className={styles.stack}>
      <div className={styles.screen}>
        <div className={styles.container}>
          <p className={styles.contentTitle}>{t("SentSignUpAuthEmail")}</p>
          <p className={styles.contentEmail}>{email}</p>
          <p className={styles.contentConfirmEmailMessage}>
            {t("signup_donot_verify")}
          </p>
          <div className={styles.buttonContainer}>
            <BigButton
              title={t("signup_resend")}
              style="disable"
              handleClick={() =>
                authResendStatus !== Status.Loading
                  ? handleClickResendButton()
                  : null
              }
            />
            <BigButton
              title={t("signup_complete")}
              style="normal"
              handleClick={() =>
                authResendStatus !== Status.Loading
                  ? handleClickSignUpCompleteButton()
                  : null
              }
            />
          </div>
        </div>
      </div>
      {isLoading === true ? <LoadingScreen /> : null}
    </div>
  );
}

export default EmailSignUpAuthScreen;
