import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Term from "../term/Term";
import styles from "./LearningScreen.module.css";
import { LearningType } from "./LearningType";
import LoadingScreen from "../common/LoadingScreen";
import { useLocation } from "react-router-dom";
import MediumButton from "../common/MediumButton";
import { LinearProgress } from "@mui/material";
import LearningOptions from "./LearningOptions";
import Flashcards from "./Flashcards";
import {
  getLearningSelectedGroupsFromLocalStorage,
  getLearningSelectedLevelFromLocalStroage,
  getLearningSortTypeFromLocalStorage,
} from "../settings/learningSettings";
import { SortType } from "../settings/SortType";
import { filter, shuffle } from "lodash";
import MultipleChoice from "./MultipleChoice";
import { getFilteredTermsWithOptions } from "../term/terms";
import Written from "./Written";
import { Status } from "../common/Status";
import { finishLearning } from "./learningAPI";
import LearningResult from "./LearningResult";
import LearningResultScreen from "./LearningResultScreen";
import { generateRandomSeed, seededShuffle } from "../common/numbers";
import { useTerms } from "../term/useTerms";

interface LearningScreenProps {
  learningType: LearningType;
  historyId?: string;
}

export default function LearningScreen() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const terms = useTerms();
  const [filteredTerms, setFilteredTerms] = useState<Term[]>([]);
  const [learningTerms, setLearningTerms] = useState<Term[]>([]);

  const [seed, setSeed] = useState(generateRandomSeed());

  const location = useLocation();
  const { learningType, historyId } = location.state as LearningScreenProps;
  const [isUpdating, setIsUpdating] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [openLearningOptions, setOpenLearningOptions] = useState(false);
  const [isReviewCurrentSection, setIsReviewCurrentSection] =
    useState<boolean>();

  const [currentPage, setCurrentPage] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTerm, setCurrentTerm] = useState<Term>();
  const [correctTerms, setCorrectTerms] = useState<Term[]>([]);
  const [incorrectTerms, setIncorrectTerms] = useState<Term[]>([]);
  const [progressRatio, setProgressRatio] = useState(0.0);
  const [finishCount, setFinishCount] = useState(0);

  const [termsUpdateResultStatus, setTermsUpdateResultStatus] = useState(
    Status.Ready
  );

  const [learningResult, setLearningResult] = useState<LearningResult>();

  useEffect(() => {}, [isUpdating]);

  useEffect(() => {
    const newFilteredTerms = getFilteredTermsWithOptions(
      terms,
      seed,
      getLearningSortTypeFromLocalStorage(),
      getLearningSelectedGroupsFromLocalStorage(),
      getLearningSelectedLevelFromLocalStroage()
    );
    setFilteredTerms(newFilteredTerms);
  }, [terms]);

  useEffect(() => {
    updateLearningTerms();
  }, [filteredTerms]);

  useEffect(() => {
    setIsUpdating(false);
  }, [currentTerm]);

  useEffect(() => {
    if (
      currentPage === 0 ||
      isReviewCurrentSection === true ||
      learningResult !== undefined
    ) {
      updateCard();
    } else {
      nextCard();
    }
  }, [learningTerms]);

  useEffect(() => {
    updateCard();
    updateRatio();
  }, [currentIndex]);

  useEffect(() => {
    switch (termsUpdateResultStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        setFinishCount(finishCount + 1);
        setIsReviewCurrentSection(undefined);
        break;
    }
  }, [termsUpdateResultStatus]);

  useEffect(() => {
    if (correctTerms.length == 0 && incorrectTerms.length == 0) {
      return;
    }
    nextCard();
  }, [correctTerms, incorrectTerms]);

  useEffect(() => {
    if (currentPage !== 0) {
      updateLearningTerms();
    }
  }, [currentPage]);

  useEffect(() => {
    if (isReviewCurrentSection === true && finishCount !== 0) {
      reviewCurrentSection();
    } else if (isReviewCurrentSection === false && finishCount !== 0) {
      continueNextSection();
    }
  }, [isReviewCurrentSection]);

  function getLearningTitle(): string {
    switch (learningType) {
      case LearningType.flashcards:
        return t("Flashcard");
      case LearningType.multiplechoice:
        return t("MultipleChoiceTitle");
      case LearningType.spellingQuiz:
        return t("SpellingQuiz");
    }
  }

  function handleOnClickOptionsButton() {
    setOpenLearningOptions(true);
  }

  function handleOnClickExitButton() {
    navigate("/");
  }

  function handleOnClickCorrectButton(term: Term) {
    const newCorrectTerms = [...correctTerms];
    newCorrectTerms.push(term);
    setCorrectTerms(newCorrectTerms);
  }

  function handleOnClickIncorrectButton(term: Term) {
    const newIncorrectTerms = [...incorrectTerms];
    newIncorrectTerms.push(term);
    setIncorrectTerms(newIncorrectTerms);
  }

  function handleOnClickLearningResultContinueButton() {
    setCorrectTerms([]);
    setIncorrectTerms([]);
    setLearningResult(undefined);
    setCurrentPage(currentPage + 1);
  }

  function handleOnClickLearningResultReviewCurrentSectionButton() {
    setIsReviewCurrentSection(true);
  }

  function updateRatio() {
    const newRatio = ((currentIndex + 1) / learningTerms.length) * 100;
    setProgressRatio(newRatio);
  }

  function nextCard() {
    setIsUpdating(true);
    const nextIndex = currentIndex + 1;
    if (nextIndex >= learningTerms.length) {
      finish();
    } else {
      setCurrentIndex(nextIndex);
    }
  }

  function updateCard() {
    if (currentIndex < learningTerms.length) {
      setCurrentTerm(
        (learningTerms[currentIndex] = {
          ...learningTerms[currentIndex],
          index: currentIndex,
        })
      );
    }
  }

  function updateLearningTerms() {
    const first = currentPage === 0 ? 0 : currentPage * 20;
    let newTerms = [...terms.slice(0, first + 20)];
    if (getLearningSortTypeFromLocalStorage() === SortType.random) {
      newTerms = shuffle(newTerms);
    }
    setLearningTerms(newTerms);
  }

  function finish() {
    if (correctTerms.length !== 0 || incorrectTerms.length !== 0) {
      updateTermsResult();
    }
  }

  async function updateTermsResult() {
    setTermsUpdateResultStatus(Status.Loading);
    try {
      const result = await finishLearning(
        correctTerms,
        incorrectTerms,
        learningType,
        historyId
      );
      result.isLastCards = currentIndex + 1 >= terms.length;
      setLearningResult(result);
      setTermsUpdateResultStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermsUpdateResultStatus(Status.Ready);
    }
  }

  function restartLearn() {
    updateLearningTerms();
    setLearningResult(undefined);
    setCorrectTerms([]);
    setCurrentIndex(currentPage);
    setIncorrectTerms([]);
    setCurrentIndex(0);
    setCurrentPage(0);
    updateCard();
  }

  function continueNextSection() {
    setCorrectTerms([]);
    setIncorrectTerms([]);
    setLearningResult(undefined);
    setCurrentPage(currentPage + 1);
  }

  function reviewCurrentSection() {
    setLearningResult(undefined);
    setCorrectTerms([]);
    setIncorrectTerms([]);
    setCurrentIndex(currentPage * 20);
    updateLearningTerms();
    updateCard();
  }

  function makeAnswers(): Term[] {
    if (currentTerm === undefined) {
      return [];
    }

    const newTerms = getFilteredTermsWithOptions(
      learningTerms,
      undefined,
      SortType.random,
      getLearningSelectedGroupsFromLocalStorage()
    );
    const correctTermIds = correctTerms.map((term) => term.id);
    let filteredTerms = newTerms
      .filter((term) => !correctTermIds.includes(term.id))
      .filter((term) => term !== currentTerm)
      .slice(0, 3);
    filteredTerms.push(currentTerm);
    return shuffle(filteredTerms);
  }

  return (
    <>
      <div className={styles.stack}>
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <div className={styles.learningTypeContainer}>
              <p className={styles.learningTypeText}>{getLearningTitle()}</p>
            </div>
            <div className={styles.groupsContainer}>
              <p className={styles.groupsText}>
                {`${currentIndex + 1} / ${learningTerms.length}`}
              </p>
            </div>
            <div className={styles.buttonsContainer}>
              <MediumButton
                title={t("Options")}
                style="clear"
                onClick={() => handleOnClickOptionsButton()}
              />
              <MediumButton
                title={t("Quit")}
                style="clear"
                onClick={() => handleOnClickExitButton()}
              />
            </div>
          </div>
          {learningResult !== undefined && (
            <LearningResultScreen
              open={learningResult !== undefined}
              result={learningResult}
              onClickContinueButton={() =>
                handleOnClickLearningResultContinueButton()
              }
              onClickReviewCurrentSectionButton={() =>
                handleOnClickLearningResultReviewCurrentSectionButton()
              }
            />
          )}
          {learningResult === undefined && (
            <div className={styles.learningContainer}>
              <div className={styles.progressContainer}>
                <LinearProgress
                  className={styles.progress}
                  variant="determinate"
                  value={progressRatio}
                  sx={{
                    backgroundColor: "var(--sub-light-gray-color)",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "var(--level-three-color)",
                    },
                  }}
                />
              </div>
              {learningType === LearningType.flashcards &&
                currentTerm !== undefined &&
                isUpdating === false && (
                  <Flashcards
                    term={currentTerm}
                    onCorrect={handleOnClickCorrectButton}
                    onIncorrect={handleOnClickIncorrectButton}
                  />
                )}
              {learningType === LearningType.multiplechoice &&
                currentTerm !== undefined &&
                isUpdating === false && (
                  <MultipleChoice
                    term={currentTerm}
                    answers={makeAnswers()}
                    onCorrect={handleOnClickCorrectButton}
                    onIncorrect={handleOnClickIncorrectButton}
                  />
                )}
              {learningType === LearningType.spellingQuiz &&
                currentTerm !== undefined &&
                isUpdating === false && (
                  <Written
                    term={currentTerm}
                    onCorrect={handleOnClickCorrectButton}
                    onIncorrect={handleOnClickIncorrectButton}
                  />
                )}
            </div>
          )}
        </div>
        <LearningOptions
          open={openLearningOptions === true}
          terms={[]}
          type={learningType}
          mode="inLearning"
          onUpdateSelectedGroups={() => {}}
          onUpdateSelectedLevels={() => {}}
          onUpdateSortType={() => {}}
          onClickStartButton={() => {}}
          onClickRestartButton={() => restartLearn()}
          onClose={() => {
            document.body.style.overflow = "auto";
            setOpenLearningOptions(false);
          }}
        />
        {isLoading === true && <LoadingScreen />}
      </div>
    </>
  );
}
