import { getAuth } from "firebase/auth";
import { getToken } from "../auth/AuthManager";
import OnevocaServerError from "../common/OnevocaServerError";
import getApiInstance from "../common/api";
import Group from "./Group";
import { auth } from "../../configurations/firebaseConfig";

export async function fetchGroups(
  withReturnAllGroup: boolean,
  withReturnNoSelectGroup: boolean
): Promise<Group[]> {
  try {
    const token = await getToken();
    const response = await getApiInstance(token).get("/group/get_list.php", {
      params: {
        is_all: withReturnAllGroup ? 1 : 0,
        is_no_group: withReturnNoSelectGroup ? 1 : 0,
      },
    });
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      const groupsData = response.data;
      const groups = groupsData.map((groupData: any) =>
        Group.fromJson(groupData)
      );
      console.log(groups);
      return groups;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addGroup(
  groupname: string,
  languageCode: string
): Promise<void> {
  const parameters = {
    n_group: groupname,
    lang: languageCode,
  };

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      "/group/add.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateGroup(
  beforeTitle: string,
  newTitle: string,
  newLanguage: string
): Promise<void> {
  const parameters = {
    beforeTitle: beforeTitle,
    newTitle: newTitle,
    newLanguage: newLanguage,
  };

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      "/group/updateGroup.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteGroup(title: string): Promise<void> {
  try {
    const token = await getToken();
    const response = await getApiInstance(token).get("/group/remove.php", {
      params: {
        gn: title,
      },
    });
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchGroupLanguageCode(group: string): Promise<string> {
  const parameters = {
    uid: auth.currentUser?.providerData[0].uid,
    group: group,
  };

  try {
    const response = await getApiInstance().post(
      "/group/get_lang.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    }

    const language = response.data.lang;
    if (language === undefined) {
      return Promise.reject(new Error("Not found language of group"));
    }
    return language;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function searchGroups(searchText: string): Promise<Group[]> {
  const parameters = {
    search: searchText,
  };

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      "/group/search.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      if (response.data.list === undefined) {
        return [];
      }
      const groupsData = response.data.list;
      const groups = groupsData.map((groupData: any) =>
        Group.fromJson(groupData)
      );
      return groups;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
