import { LearningType } from "./LearningType";
import styles from "./LearningTypeCell.module.css";

interface LearningTypeCellProps {
  type: LearningType;
  title: string;
  description: string;
  image: string;
  onClick: () => void;
}

export default function LearningTypeCell(props: LearningTypeCellProps) {
  return (
    <>
      <div className={styles.container} onClick={() => props.onClick()}>
        <img className={styles.typeIcon} src={props.image} />
        <p className={styles.titleText}>{props.title}</p>
        <p className={styles.messageText}>{props.description}</p>
      </div>
    </>
  );
}
