import { TermLevel } from "./TermLevel";
import i18n from "../../i18n";

export enum TermLevelUpdate {
  increase,
  reduce,
  none,
}

export default class Term {
  id: string;
  term: string;
  definition: string;
  memo: string;
  pronunciation: string;
  group: string;
  level: TermLevel;
  exp: number;
  isShowDetail: boolean = false;
  selected: boolean = false;
  expMovePoint: number = 0;
  isCorrectAnswer: boolean;
  updatedLevel: TermLevelUpdate;
  index?: number;

  constructor(
    id: string,
    term: string,
    definition: string,
    memo: string,
    pronunciation: string,
    group: string,
    level: TermLevel,
    exp: number,
    expMovePoint: number,
    isCorrectAnswer: boolean,
    updateLevel: TermLevelUpdate,
    index?: number
  ) {
    this.id = id;
    this.term = term;
    this.definition = definition;
    this.memo = memo;
    this.pronunciation = pronunciation;
    this.group = group;
    this.level = level;
    this.exp = exp;
    this.expMovePoint = expMovePoint;
    this.isCorrectAnswer = isCorrectAnswer;
    this.updatedLevel = updateLevel;
    this.index = index;
  }

  static toJson(term: Term): any {
    return {
      id: term.id,
      term: term.term,
      definition: term.definition,
      memo: term.memo,
      pronunciation: term.pronunciation,
      group: term.group,
      level: term.level,
      exp: term.exp,
      isShowDetail: term.isShowDetail,
      selected: term.selected,
    };
  }

  static fromJson(json: any): Term {
    let level = TermLevel.difficult;
    switch (json.lv ?? json.new_level ?? json.level) {
      case "0":
      case 0:
        level = TermLevel.difficult;
        break;
      case "1":
      case 1:
        level = TermLevel.familiar;
        break;
      case "2":
      case 2:
        level = TermLevel.perfect;
        break;
    }

    let updatedLevel = TermLevelUpdate.none;
    switch (json.change) {
      case 1:
        updatedLevel = TermLevelUpdate.increase;
        break;
      case -1:
        updatedLevel = TermLevelUpdate.reduce;
        break;
      case 0:
        updatedLevel = TermLevelUpdate.none;
        break;
    }
    return new Term(
      json.nid ?? json.id,
      json.wd ?? json.word ?? json.term,
      json.mn ?? json.mean ?? json.definition,
      json.de ?? json.desc ?? json.memo,
      json.pr ?? json.pron ?? json.pronunciation,
      json.gr ?? json.group,
      level,
      json.exp,
      json.move_point ?? json.expMovePoint,
      json.true_and_false ?? json.isCorrectAnswer,
      updatedLevel,
      json.index
    );
  }
}

export function getUpdatedLevelString(updatedLevel: TermLevelUpdate): string {
  switch (updatedLevel) {
    case TermLevelUpdate.increase:
      return "Level up 🎉";
    case TermLevelUpdate.reduce:
      return "Level down 😥";
    case TermLevelUpdate.none:
      return "";
  }
}

export function getLevelString(level: TermLevel): string {
  switch (level) {
    case TermLevel.difficult:
      return i18n.t("Difficult");
    case TermLevel.familiar:
      return i18n.t("Familiar");
    case TermLevel.perfect:
      return i18n.t("Perfect");
  }
}

export function getGroupString(group: string): string {
  switch (group) {
    case "":
      return i18n.t("NoSelectGroup");
    default:
      return group;
  }
}

export function getNextLevel(level: TermLevel): TermLevel {
  switch (level) {
    case TermLevel.difficult:
      return TermLevel.familiar;
    case TermLevel.familiar:
      return TermLevel.perfect;
    case TermLevel.perfect:
      return TermLevel.difficult;
  }
}

export const termMaxSize = 5000;
export const definitionMaxSize = 5000;
export const memoMaxSize = 5000;
export const pronunciationMaxSize = 5000;
