import { getToken } from "../auth/AuthManager";
import OnevocaServerError from "../common/OnevocaServerError";
import getApiInstance from "../common/api";
import Term from "../term/Term";
import LearningResult from "./LearningResult";
import { LearningType } from "./LearningType";

function createFinishLearningParameters(
  learningType: LearningType,
  historyId: string | undefined,
  correctTerms: Term[],
  incorrectTerms: Term[]
): any {
  const parameters: any = {};
  const correctTermParameters: any = {};
  const incorrectTermParameters: any = {};

  switch (learningType) {
    case LearningType.flashcards:
      parameters["game_type"] = "flash_card";
      break;
    case LearningType.multiplechoice:
      parameters["game_type"] = "multiple_choice";
      break;
    case LearningType.spellingQuiz:
      parameters["game_type"] = "spell_quiz";
      break;
  }

  if (historyId) {
    parameters["historyId"] = historyId;
  }

  correctTerms.forEach((term) => {
    correctTermParameters[term.id] = {
      index: term.index,
      id: term.id,
      exp: term.exp,
      true_and_false: true,
    };
  });

  incorrectTerms.forEach((term) => {
    incorrectTermParameters[term.id] = {
      index: term.index,
      id: term.id,
      exp: term.exp,
      true_and_false: false,
    };
  });

  parameters["words_correct"] = correctTermParameters;
  parameters["words_incorrect"] = incorrectTermParameters;

  return parameters;
}

export async function finishLearning(
  correctTerms: Term[],
  incorrectTerms: Term[],
  learningType: LearningType,
  historyId?: string
): Promise<LearningResult> {
  const parameters = createFinishLearningParameters(
    learningType,
    historyId,
    correctTerms,
    incorrectTerms
  );

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      "/learning/updateLearningFinishedTermsLevel.php",
      parameters
    );

    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    }

    const termsData = response.data.data.terms;
    if (termsData === undefined) {
      return Promise.reject(new Error("No data."));
    }

    const historyId = response.data.data.historyId;
    const terms = termsData.map((termData: any) => Term.fromJson(termData));
    const result = new LearningResult(terms, historyId);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}
