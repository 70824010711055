import { AuthErrorCodes } from "firebase/auth";
import { FirebaseError } from "firebase/app";

export const getFirebaseErrorMessage = (
  error: Error,
  t: (key: string) => string
): string => {
  if (error instanceof FirebaseError) {
    switch (error.code) {
      case AuthErrorCodes.NETWORK_REQUEST_FAILED:
        return t("CheckNetwork");
      case AuthErrorCodes.USER_DELETED:
        return t("signin_not_fount_user");
      case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
        return t("signin_too_many_requests");
      case AuthErrorCodes.INVALID_EMAIL:
        return t("signup_warning_correct_email");
      case AuthErrorCodes.INVALID_PASSWORD:
        return t("signin_not_fount_user");
      case AuthErrorCodes.EMAIL_EXISTS:
        return t("signin_already_use_in_email");
      default:
        return error.message;
    }
  } else {
    return "Unknown Error";
  }
};
