import { useEffect, useState } from "react";
import { Status } from "./Status";

interface TermEditState {
  isLoading: boolean;
  errorMsg: string | null;
}

export default function useTermEditor(): TermEditState {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [status, setStatus] = useState<Status>(Status.Ready);
  const [editType, setEditType] = useState<string>("delete");

  useEffect(() => {
    // TODO: Consider status and editType

    setIsLoading(false); // Temp
    setErrorMsg("");
  }, [status]);

  return {
    isLoading: isLoading,
    errorMsg: errorMsg,
  };
}
