import axios, { AxiosInstance } from "axios";
import { serverUrl } from "./contants";

export default function getApiInstance(token?: string): AxiosInstance {
  const apiInstance = axios.create({
    baseURL: serverUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return apiInstance;
}
