import styles from "./AccountMenu.module.css";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ProfileIcon from "./ProfileIcon";
import Divider from "../common/Divider";
import SmallButton from "../common/SmallButton";
import {
  getLastUpdatedProfileImage,
  getMyTermsCountFromLocalStroage,
  getSlotsFromLocalStorage,
} from "../settings/userSettings";
import { formatNumberWithCommas } from "../common/strings";
import { auth } from "../../configurations/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { logout } from "./AuthManager";
import { useNavigate } from "react-router-dom";
import { profileImageUrl } from "../common/contants";

interface AccountMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClickSlotAddButton: () => void;
  onClickSettingsButton: () => void;
  onClickLogOutButton: () => void;
  onClickContactUsButton: () => void;
  onClose: () => void;
}

export default function AccountMenu(props: AccountMenuProps) {
  const navigate = useNavigate();

  const termsCount = formatNumberWithCommas(getMyTermsCountFromLocalStroage());
  const slotCount = formatNumberWithCommas(getSlotsFromLocalStorage());

  const { t } = useTranslation();
  const [menuStyles, setMenuStyles] = useState<React.CSSProperties>({});
  const [profileIconSrc, setProfileIconSrc] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setProfileIconSrc(
          `${profileImageUrl}${
            user.providerData[0].uid
          }.jpg?t=${getLastUpdatedProfileImage()}`
        );
      } else {
        logout();
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (props.anchorEl && props.open) {
      const rect = props.anchorEl.getBoundingClientRect();
      setMenuStyles({
        position: "absolute",
        width: "250px",
        top: rect.bottom + window.scrollY,
        left: rect.right + window.scrollX - 250,
        marginTop: "8px",
        zIndex: 50,
        backgroundColor: "var(--white-color)",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
      });
    }
  }, [props.anchorEl, props.open]);

  function handleOnClickContainer(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    props.onClose();
  }

  function handleOnClickContentContainer(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function handleOnClickInstagram() {
    window.open("https://www.instagram.com/onevoca_kr/");
  }

  if (props.open === false || props.anchorEl === null) {
    return null;
  }

  return (
    <>
      <div className={styles.container} onClick={handleOnClickContainer}>
        <div style={menuStyles} onClick={handleOnClickContentContainer}>
          <div className={styles.userInfoContainer}>
            <ProfileIcon
              size="40px"
              src={profileIconSrc}
              onCursorPoint={false}
              onClick={() => {}}
            />
            <div className={styles.userInfoTextContainer}>
              <p className={styles.usernameText}>
                {auth.currentUser?.displayName}
              </p>
              <p className={styles.userEmailText}>
                {auth.currentUser?.providerData[0].email}
              </p>
            </div>
          </div>
          <div className={styles.slotContainer}>
            <p className={styles.mySlotCountText}>
              <Trans i18nKey="MySlots" values={{ termsCount, slotCount }}>
                <span className={styles.mySlotCountTextHighlight}>
                  {termsCount}
                </span>
                <span className={styles.mySlotCountTextHighlight}>
                  {slotCount}
                </span>
              </Trans>
            </p>
            <SmallButton
              title={t("SlotAddButton")}
              style="normal"
              onClick={() => props.onClickSlotAddButton()}
            />
          </div>
          <Divider />
          <div className={styles.buttonsContainer}>
            <div className={styles.featursContainer}>
              <button
                className={styles.button}
                onClick={() => props.onClickSettingsButton()}
              >
                {t("Settings")}
              </button>
            </div>
          </div>
          <Divider />
          <div className={styles.logOutContainer}>
            <button
              className={styles.button}
              onClick={() => props.onClickLogOutButton()}
            >
              {t("SignOut")}
            </button>
          </div>
          <Divider />
          <div className={styles.otherContainer}>
            <button
              className={styles.button}
              onClick={() => props.onClickContactUsButton()}
            >
              {t("ContactUs")}
            </button>
            <button
              className={styles.button}
              onClick={() => handleOnClickInstagram()}
            >
              {t("Instagram")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
