import { useEffect, useState } from "react";
import LeftTabBarButton from "./LeftTabBarButton";
import styles from "./leftTabBar.module.css";
import { useTranslation } from "react-i18next";
import { MenuRounded } from "@mui/icons-material";

interface LeftTabBarProps {
  open: boolean;
  onChangeSelectedTab: (selectedTab: "list" | "learn") => void;
  clickEmptyContainer: () => void;
  clickToggleLeftBarButton: () => void;
}

export default function LeftTabBar(props: LeftTabBarProps) {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<"list" | "learn">("list");

  useEffect(() => {
    props.onChangeSelectedTab(selectedTab);
  }, [selectedTab]);

  function handleOnClickContentContainer(
    event: React.MouseEvent<HTMLDivElement>
  ) {
    event.stopPropagation();
  }

  function handleOnClickTab(tab: "list" | "learn") {
    switch (tab) {
      case "list":
        setSelectedTab("list");
        break;
      case "learn":
        setSelectedTab("learn");
        break;
    }
  }

  if (props.open === true) {
    return (
      <>
        <div
          className={styles.container}
          onClick={() => props.clickEmptyContainer()}
        >
          <div
            className={styles.contentContainer}
            onClick={handleOnClickContentContainer}
          >
            <div className={styles.topNavContainer}>
              <div className={styles.leftButtonsContainer}>
                <div onClick={() => props.clickToggleLeftBarButton()}>
                  <MenuRounded className={styles.leftBarToggleButton} />
                </div>
                <img className={styles.onevocaLogo} src="imgOnevocaLogo.png" />
              </div>
            </div>
            <div className={styles.tabBarButtons}>
              <LeftTabBarButton
                title={t("TermListTitle")}
                type="list"
                selected={selectedTab === "list"}
                onClick={handleOnClickTab}
              />
              <LeftTabBarButton
                title={t("LearningTitle")}
                type="learn"
                selected={selectedTab === "learn"}
                onClick={handleOnClickTab}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
