import styles from "./LoginButton.module.css";
import classNames from "classnames";

interface LoginButtonProps {
  title: string;
  image: string;
  titleColor: string;
  backgroundColor: string;
  imageColor?: string | null;
  borderType: "none" | "solid";
  handleClick: () => void;
}

export default function LoginButton(props: LoginButtonProps) {
  const buttonClass = classNames(styles.loginButton, {
    [styles.borderNone]: props.borderType === "none",
    [styles.borderSolid]: props.borderType === "solid",
  });

  const buttonColor = {
    color: props.titleColor,
    backgroundColor: props.backgroundColor,
  };

  const imageStyle = props.imageColor ? { fill: props.imageColor } : {};

  return (
    <>
      <button
        className={buttonClass}
        style={buttonColor}
        onClick={() => props.handleClick()}
      >
        <img
          className={styles.buttonImage}
          src={props.image}
          style={imageStyle}
          alt="Icon"
        />
        <p className={styles.buttonTitle}>{props.title}</p>
      </button>
    </>
  );
}
