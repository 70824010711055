import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLogIn: localStorage.getItem("isLogIn") === "true",
  },
  reducers: {
    logIn: (state) => {
      state.isLogIn = true;
      localStorage.setItem("isLogIn", "true");
    },
    logOut: (state) => {
      state.isLogIn = false;
      localStorage.removeItem("isLogIn");
    },
  },
});

export const { logIn, logOut } = authSlice.actions;
export default authSlice.reducer;
