import React, { useEffect } from "react";

interface EnterKeyListenerComponentProps {
  onEnterPress: () => void; // Enter 키가 눌렸을 때 실행할 함수
}

const EnterKeyListenerComponent: React.FC<EnterKeyListenerComponentProps> = ({
  onEnterPress,
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onEnterPress();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onEnterPress]);

  return <></>;
};

export default EnterKeyListenerComponent;
