import Term from "../term/Term";
import styles from "./MultipleChoice.module.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import SpeakButton from "../common/SpeakButton";
import { fetchGroupLanguageCode } from "../groups/groupAPI";
import { getVoiceFile, makeBase64String } from "../voice/voice";
import { useEffect, useState } from "react";
import {
  getLearningPronunciationPositionFromLocalStroage,
  getLearningQuestionTypeFromLocalStorage,
  isOnLearningTextToSpeechFromLocalStorage,
} from "../settings/learningSettings";
import { QuestionType } from "../settings/QuestionType";
import { auth } from "../../configurations/firebaseConfig";
import { PronunciationPosition } from "../settings/PronunciationPosition";
import { MultipleChoiceAnswerButton } from "./MultipleChoiceAnswerButton";
import classNames from "classnames";
import { delay } from "lodash";
import AudioPlayer from "react-h5-audio-player";
import EnterKeyListenerComponent from "../common/keyListeners";

interface MultipleChoiceProps {
  term: Term;
  answers: Term[];
  onCorrect: (term: Term) => void;
  onIncorrect: (term: Term) => void;
}

export default function MultipleChoice(props: MultipleChoiceProps) {
  const { t } = useTranslation();

  const [audioSrc, setAudioSrc] = useState<string>();
  const randomChoice = Math.random() < 0.5 ? "term" : "definition";
  const [currentQuestion, setCurrentQuestion] = useState<"term" | "definition">(
    getCurrentQuestionType()
  );

  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState<string[]>([]);
  const [questionUpdateCount, setQuestionUpdateCount] = useState(0);
  const [currentGroup, setCurrentGroup] = useState("");
  const [currentGroupUpdateCount, setCurrentGroupUpdateCount] = useState(0);
  const [speakCount, setSpeakCount] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isResultMode, setIsResultMode] = useState(false);
  const [isAnswerResult, setIsAnswerResult] = useState(false);

  useEffect(() => {
    reload();
  }, [props.term]);

  useEffect(() => {
    const list: string[] = [];
    switch (currentQuestion) {
      case "term":
        props.answers.forEach((value: Term) => {
          list.push(value.definition);
        });
        break;
      case "definition":
        props.answers.forEach((value: Term) => {
          list.push(value.term);
        });
        break;
    }
    setAnswers(list);
  }, [props.answers, currentQuestion]);

  useEffect(() => {
    setCurrentGroupUpdateCount(currentGroupUpdateCount + 1);
  }, [currentGroup]);

  useEffect(() => {
    setQuestionUpdateCount(questionUpdateCount + 1);
  }, [question]);

  useEffect(() => {
    if (
      speakCount === 0 &&
      currentGroupUpdateCount > 0 &&
      questionUpdateCount > 0 &&
      auth.currentUser !== null
    ) {
      if (isOnLearningTextToSpeechFromLocalStorage() === true) {
        speak();
      }
    }
  }, [question, currentGroup]);

  useEffect(() => {
    setQuestion(
      currentQuestion === "term" ? props.term.term : props.term.definition
    );
    setCurrentGroup(currentQuestion === "term" ? props.term.group : "");
  }, [currentQuestion, props.term]);

  function isShowMemo(): boolean {
    return (
      currentQuestion === "definition" && props.term.pronunciation.length !== 0
    );
  }

  function isShowPronunciation(): boolean {
    if (
      currentQuestion === "term" &&
      getLearningPronunciationPositionFromLocalStroage() ===
        PronunciationPosition.onTerm &&
      props.term.memo.length !== 0
    ) {
      return true;
    } else if (
      currentQuestion === "definition" &&
      getLearningPronunciationPositionFromLocalStroage() ===
        PronunciationPosition.onDefinition &&
      props.term.memo.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  function isAnswer(value?: string) {
    switch (currentQuestion) {
      case "term":
        return props.term.definition === value;
      case "definition":
        return props.term.term === value;
    }
  }

  function handleOnClickSpeakButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    speak();
  }

  function handleOnClickAnswerButton(isAnswer: boolean) {
    setIsResultMode(true);
    setIsAnswerResult(isAnswer);
    if (isAnswer) {
      delay(() => {
        props.onCorrect(props.term);
      }, 1000);
    }
  }

  function handleOnClickPassButton() {
    if (isResultMode) {
      return;
    }
    props.onIncorrect(props.term);
  }

  function handleOnClickContinueButton() {
    props.onIncorrect(props.term);
  }

  function handleOnPressEnterKey() {
    if (isResultMode === true && isAnswerResult !== true) {
      handleOnClickContinueButton();
    }
  }

  async function speak() {
    setSpeakCount(speakCount + 1);
    setIsPlaying(true);
    try {
      const languageCode =
        currentGroup.length !== 0
          ? await fetchGroupLanguageCode(currentGroup)
          : "";
      const audio = await getVoiceFile(question, languageCode);
      setAudioSrc(makeBase64String(audio));
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
    }
  }

  function reload() {
    setIsAnswerResult(false);
    setIsResultMode(false);
    setSpeakCount(0);
    updateCurrentQuestionType();
  }

  function getCurrentQuestionType(): "term" | "definition" {
    switch (getLearningQuestionTypeFromLocalStorage()) {
      case QuestionType.termFirst:
        return "term";
      case QuestionType.definitionFirst:
        return "definition";
      case QuestionType.random:
        return randomChoice;
    }
  }

  function updateCurrentQuestionType() {
    switch (getLearningQuestionTypeFromLocalStorage()) {
      case QuestionType.termFirst:
        setCurrentQuestion("term");
        break;
      case QuestionType.definitionFirst:
        setCurrentQuestion("definition");
        break;
      case QuestionType.random:
        setCurrentQuestion(randomChoice);
        break;
    }
  }

  const answerTitleTextClass = classNames(styles.answerTitleText, {
    [styles.answerTitleTextCorrect]:
      isAnswerResult === true && isResultMode === true,
    [styles.answerTitleTextIncorrect]:
      isAnswerResult === false && isResultMode === true,
  });

  const passButtonClass = classNames(styles.passButton, {
    [styles.passButtonDisable]: isResultMode,
  });

  return (
    <>
      <motion.div
        className={styles.containerForMotion}
        key={props.term.term}
        initial={{ x: 100, opacity: 0 }}
        animate={{
          rotateX: 0,
          x: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
        style={{
          transformStyle: "preserve-3d",
        }}
      >
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <SpeakButton
              fontSize="small"
              isPlaying={isPlaying}
              onClick={handleOnClickSpeakButton}
            />
          </div>
          <div className={styles.contentsContainer}>
            <div className={styles.questionContainer}>
              {isShowPronunciation() === true && (
                <p className={styles.pronunciationText}>
                  {`[${props.term.pronunciation}]`}
                </p>
              )}
              <p className={styles.questionText}>{question}</p>
              {isShowMemo() && (
                <p className={styles.memoText}>{props.term.memo}</p>
              )}
            </div>
            <div className={styles.answerContainer}>
              <p className={answerTitleTextClass}>
                {isResultMode === true
                  ? isAnswerResult
                    ? t("CorrectAnswerMessage")
                    : t("IncorrectAnswerMessage")
                  : t("ChooseCorrectDefinitionMessage")}
              </p>
              <div className={styles.answerButtonsContainer}>
                <div className={styles.answerButtonsFirstLine}>
                  <MultipleChoiceAnswerButton
                    isAnswer={isAnswer(answers[0])}
                    isResultMode={isResultMode}
                    value={answers[0]}
                    onClick={handleOnClickAnswerButton}
                  />
                  <MultipleChoiceAnswerButton
                    isAnswer={isAnswer(answers[1])}
                    isResultMode={isResultMode}
                    value={answers[1]}
                    onClick={handleOnClickAnswerButton}
                  />
                </div>
                <div className={styles.answerButtonsSecondLine}>
                  <MultipleChoiceAnswerButton
                    isAnswer={isAnswer(answers[2])}
                    isResultMode={isResultMode}
                    value={answers[2]}
                    onClick={handleOnClickAnswerButton}
                  />
                  <MultipleChoiceAnswerButton
                    isAnswer={isAnswer(answers[3])}
                    isResultMode={isResultMode}
                    value={answers[3]}
                    onClick={handleOnClickAnswerButton}
                  />
                </div>
              </div>
              <div className={styles.passButtonContainer}>
                <button
                  className={passButtonClass}
                  onClick={() => handleOnClickPassButton()}
                  onMouseEnter={(e) => {
                    if (isResultMode === true) {
                      return;
                    }
                    (
                      e.currentTarget as HTMLButtonElement
                    ).style.backgroundColor = "var(--primary-color-opacity)";
                  }}
                  onMouseLeave={(e) => {
                    if (isResultMode === true) {
                      return;
                    }
                    (
                      e.currentTarget as HTMLButtonElement
                    ).style.backgroundColor = "";
                  }}
                >
                  {t("PassButton")}
                </button>
                {isResultMode === true && isAnswerResult !== true && (
                  <button
                    className={styles.continueButton}
                    onClick={() => handleOnClickContinueButton()}
                  >
                    {t("Continue")}
                  </button>
                )}
              </div>
            </div>
          </div>
          {audioSrc && (
            <AudioPlayer
              autoPlay={true}
              src={audioSrc}
              style={{ display: "none" }}
              onEnded={() => {
                setIsPlaying(false);
                setAudioSrc(undefined);
              }}
            />
          )}
        </div>
      </motion.div>
      <EnterKeyListenerComponent onEnterPress={() => handleOnPressEnterKey()} />
    </>
  );
}
