import { Box, CircularProgress, Typography } from "@mui/material";
import LearningResult from "./LearningResult";
import styles from "./LearningResultScreen.module.css";
import { useEffect, useState } from "react";
import Term, { getNextLevel } from "../term/Term";
import { useTranslation } from "react-i18next";
import TermListGrid from "../term/TermListGrid";
import { QuestionType } from "../settings/QuestionType";
import { getLearningPronunciationPositionFromLocalStroage } from "../settings/learningSettings";
import BigButton from "../common/BigButton";
import {
  updateTermLevel as updateTermLevelFromAPI,
  updateTerm as updateTermFromAPI,
  deleteTerm as deleteTermFromAPI,
} from "../term/termAPI";
import { useAppDispatch, useAppSelector } from "../../hook";
import { TermLevel, getCurrentLevelExp } from "../term/TermLevel";
import {
  selectTerms,
  updatePartialTerms,
  updateTerms,
} from "../term/termSlice";
import {
  deleteAndReturnTerm,
  updateAndReturnTerm,
  updateAndReturnTermLevel,
} from "../term/terms";
import BasicMenu, { BasicMenuButton } from "../common/BasicMenu";
import TermEditScreen from "../term/TermEditScreen";
import OnevocaDialog from "../common/OnevocaDialog";
import { Status } from "../common/Status";
import LoadingScreen from "../common/LoadingScreen";

interface LearningResultScreenProps {
  open: boolean;
  result?: LearningResult;
  onClickContinueButton: () => void;
  onClickReviewCurrentSectionButton: () => void;
}

export default function LearningResultScreen(props: LearningResultScreenProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const termsDispatch = useAppDispatch();
  const rawTerms = useAppSelector(selectTerms);

  const [correctTerms, setCorrectTerms] = useState<Term[]>([]);
  const [incorrectTerms, setIncorrectTerms] = useState<Term[]>([]);
  const [terms, setTerms] = useState<Term[]>([]);
  const [correctRatio, setCorrectRatio] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [termEditStatus, setTermEditStatus] = useState(Status.Ready);
  const [termDeleteStatus, setTermDeleteStatus] = useState(Status.Ready);

  const [termEditSelectedItem, setTermEditSelectedItem] = useState<Term | null>(
    null
  );
  const [termDeleteSelectedItem, setTermDeleteSelectedItem] =
    useState<Term | null>(null);

  const [isOpenTermCellMoreMenu, setIsOpenTermCellMoreMenu] =
    useState<Term | null>(null);

  const getTermCellMoreButtons = (): BasicMenuButton[] => [
    {
      title: t("Edit"),
      handleOnClose: () => {
        const term = isOpenTermCellMoreMenu;
        if (term !== null) {
          setTermEditSelectedItem(term);
        }
        setIsOpenTermCellMoreMenu(null);
      },
    },
    {
      title: t("Delete"),
      handleOnClose: () => {
        const term = isOpenTermCellMoreMenu;
        if (term !== null) {
          setTermDeleteSelectedItem(term);
        }
        setIsOpenTermCellMoreMenu(null);
      },
    },
  ];

  useEffect(() => {
    if (props.result !== undefined) {
      const newCorrectTerms = props.result.terms.filter(
        (term) => term.isCorrectAnswer
      );
      const newIncorrectTerms = props.result.terms.filter(
        (term) => !term.isCorrectAnswer
      );

      setCorrectTerms(newCorrectTerms);
      setIncorrectTerms(newIncorrectTerms);
    }
  }, [props.open, props.result]);

  useEffect(() => {
    const newRatio = (correctTerms.length / props.result!.terms.length) * 100;
    const ratio = Math.floor(newRatio);
    setCorrectRatio(ratio);

    const newTerms = [...correctTerms, ...incorrectTerms];
    const serializedTerms = newTerms.map((term) => Term.toJson(term));
    setTerms(newTerms);
    termsDispatch(updatePartialTerms(serializedTerms));
  }, [correctTerms, incorrectTerms]);

  useEffect(() => {
    switch (termEditStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        break;
    }
  }, [termEditStatus]);

  useEffect(() => {
    switch (termDeleteStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        break;
    }
  }, [termDeleteStatus]);

  useEffect(() => {
    if (termEditSelectedItem !== null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [termEditSelectedItem]);

  function handleOnClickContinueButton() {
    props.onClickContinueButton();
  }

  function handleOnClickReviewCurrentSectionButton() {
    props.onClickReviewCurrentSectionButton();
  }

  function handleOnClickTermCellMoreButton(
    event: React.MouseEvent<HTMLDivElement>,
    term: Term
  ) {
    setAnchorEl(event.currentTarget);
    setIsOpenTermCellMoreMenu(term);
  }

  async function handleOnClickTermLevel(term: Term) {
    try {
      const nextLevel = getNextLevel(term.level);
      updateTermLevelFromAPI(term, nextLevel);
      updateTermLevelToList(term, nextLevel);
      updateTermLevelToCurrentList(term, nextLevel);
      updateTermLevelToIncorrectList(term, nextLevel);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
    }
  }

  async function updateTerm(term: Term) {
    setTermEditStatus(Status.Loading);
    try {
      await updateTermFromAPI(term);
      updateTermToList(term);
      updateTermToCorrectList(term);
      updateTermToIncorrectList(term);
      setTermEditStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermEditStatus(Status.Ready);
    }
  }

  async function deleteTerm(term: Term) {
    setTermDeleteStatus(Status.Loading);
    try {
      await deleteTermFromAPI(term);
      deleteTermToList(term);
      deleteTermToCorrectList(term);
      deleteTermToIncorrectList(term);
      setTermDeleteStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermDeleteStatus(Status.Ready);
    }
  }

  function deleteTermToList(term: Term) {
    const terms = rawTerms.map((rawTerm: any) => Term.fromJson(rawTerm));
    const termIndex = terms.findIndex((t) => t.id === term.id);
    if (termIndex !== -1) {
      const newTerms = [...terms];
      newTerms.splice(termIndex, 1);
      const serializedTerms = newTerms.map((term) => Term.toJson(term));
      termsDispatch(updateTerms(serializedTerms));
    }
  }

  function deleteTermToCorrectList(term: Term) {
    const newCorrectTerms = deleteAndReturnTerm(correctTerms, term);
    setCorrectTerms(newCorrectTerms);
  }

  function deleteTermToIncorrectList(term: Term) {
    const newIncorrectTerms = deleteAndReturnTerm(incorrectTerms, term);
    setIncorrectTerms(newIncorrectTerms);
  }

  function updateTermToList(term: Term) {
    const terms = rawTerms.map((rawTerm: any) => Term.fromJson(rawTerm));
    const termIndex = terms.findIndex((t) => t.id === term.id);
    if (termIndex !== -1) {
      const newTerms = [...terms];
      newTerms[termIndex] = term;
      const serializedTerms = newTerms.map((term) => Term.toJson(term));
      termsDispatch(updateTerms(serializedTerms));
    }
  }

  function updateTermToCorrectList(term: Term) {
    const newCorrectTerms = updateAndReturnTerm(correctTerms, term);
    setCorrectTerms(newCorrectTerms);
  }

  function updateTermToIncorrectList(term: Term) {
    const newIncorrectTerms = updateAndReturnTerm(incorrectTerms, term);
    setIncorrectTerms(newIncorrectTerms);
  }

  function updateTermLevelToList(term: Term, toLevel: TermLevel) {
    const terms = rawTerms.map((rawTerm: any) => Term.fromJson(rawTerm));
    const termIndex = terms.findIndex((t) => t.id === term.id);
    if (termIndex !== -1) {
      const newTerms = [...terms];
      newTerms[termIndex] = {
        ...newTerms[termIndex],
        level: toLevel,
        exp: getCurrentLevelExp(toLevel),
      };
      const serializedTerms = newTerms.map((term) => Term.toJson(term));
      termsDispatch(updateTerms(serializedTerms));
    }
  }

  function updateTermLevelToCurrentList(term: Term, toLevel: TermLevel) {
    const newCorrectTerms = updateAndReturnTermLevel(
      correctTerms,
      term,
      toLevel,
      getCurrentLevelExp(toLevel)
    );
    setCorrectTerms(newCorrectTerms);
  }

  function updateTermLevelToIncorrectList(term: Term, toLevel: TermLevel) {
    const newIncorrectTerms = updateAndReturnTermLevel(
      incorrectTerms,
      term,
      toLevel,
      getCurrentLevelExp(toLevel)
    );
    setIncorrectTerms(newIncorrectTerms);
  }

  if (props.open === false || props.result === undefined) {
    return null;
  }

  return (
    <>
      <div className={styles.stack}>
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <div className={styles.titleContainer}>
              <p className={styles.titleText}>
                {props.result.isLastCards
                  ? t("LearningFinishTitle")
                  : t("LearningContinueTitle")}
              </p>
              <p className={styles.titleIcon}>🎉</p>
            </div>
            <div className={styles.progressContainer}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: "var(--sub-light-gray-color-opacity)",
                  }}
                  size={120}
                  thickness={4}
                  {...props}
                  value={100}
                />
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: "var(--level-three-color)",
                    position: "absolute",
                    strokeLinecap: "round",
                  }}
                  size={120}
                  thickness={4}
                  {...props}
                  value={correctRatio}
                />
                <Typography
                  sx={{
                    color: "var(--level-three-color)",
                    fontSize: "24px",
                    position: "absolute",
                  }}
                >
                  {`${correctRatio}%`}
                </Typography>
              </Box>
              <div className={styles.progressCorrectOrIncorrectContainer}>
                <div className={styles.progressCorrectTermsCountContainer}>
                  <p className={styles.progressCorrectTermsCountText}>
                    {t("KnowButton")}
                  </p>
                  <p className={styles.progressCorrectTermsCountText}>
                    {correctTerms.length}
                  </p>
                </div>
                <div className={styles.progressIncorrectTermsCountContainer}>
                  <p className={styles.progressIncorrectTermsCountText}>
                    {t("StillLearningButton")}
                  </p>
                  <p className={styles.progressIncorrectTermsCountText}>
                    {incorrectTerms.length}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              {props.result.isLastCards === false && (
                <BigButton
                  title={t("LearningFinishContinueButton")}
                  style="normal"
                  handleClick={() => handleOnClickContinueButton()}
                />
              )}
              <BigButton
                title={t("LearningFinishRepeatButton")}
                style="clear"
                handleClick={() => handleOnClickReviewCurrentSectionButton()}
              />
            </div>
            <div className={styles.termsContainer}>
              <TermListGrid
                terms={terms.sort((a, b) => {
                  return a.index! - b.index!;
                })}
                handleOnClick={() => {}}
                handleOnClickLevelButton={handleOnClickTermLevel}
                handleOnClickMoreButton={(event, term) => {
                  handleOnClickTermCellMoreButton(event, term);
                }}
                config={{
                  style: "learningResult",
                  firstShow: QuestionType.termFirst,
                  pronunciationPosition:
                    getLearningPronunciationPositionFromLocalStroage(),
                  isSelectionMode: false,
                  isShowDetailMode: true,
                  hideMoreButton: true,
                }}
              />
            </div>
          </div>
        </div>
        <BasicMenu
          isOpen={isOpenTermCellMoreMenu !== null}
          anchorEl={anchorEl}
          buttons={getTermCellMoreButtons()}
          handleOnClose={() => {
            setIsOpenTermCellMoreMenu(null);
          }}
        />
        <TermEditScreen
          open={termEditSelectedItem !== null}
          term={termEditSelectedItem!}
          handleOnSave={(term: Term) => updateTerm(term)}
          handleOnClose={() => {
            setTermEditSelectedItem(null);
          }}
        />
        <OnevocaDialog
          open={termDeleteSelectedItem !== null}
          title={null}
          message={t("areyousuremesssage")}
          buttons={[
            {
              title: t("Delete"),
              style: "solid",
              onClick: () => {
                const term = termDeleteSelectedItem;
                if (term !== null) {
                  setTermDeleteSelectedItem(null);
                  deleteTerm(term);
                }
              },
            },
            {
              title: t("Cancel"),
              style: "normal",
              onClick: () => {
                setTermDeleteSelectedItem(null);
              },
            },
          ]}
        />
        {isLoading === true && <LoadingScreen />}
      </div>
    </>
  );
}
