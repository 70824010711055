import styles from "./ListEmptyText.module.css";

interface ListEmptyTextProps {
  text: string;
}

export default function ListEmptyText(props: ListEmptyTextProps) {
  return (
    <>
      <p className={styles.text}>{props.text}</p>
    </>
  );
}
