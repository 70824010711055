import styles from "./MaintenanceScreen.module.css";
import { useTranslation } from "react-i18next";

function MaintenanceScreen() {
  const { t } = useTranslation();

  return (
    <div className={styles.screen}>
      <p className={styles.contentText}>{t("ServerInspectMessage")}</p>
    </div>
  );
}

export default MaintenanceScreen;
