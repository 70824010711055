import axios from "axios";
import { serverUrl } from "../common/contants";

export enum ServerStatus {
  Running = "Running",
  Maintenance = "Maintenance",
}

export async function fetchServerStatus(): Promise<ServerStatus> {
  try {
    const response = await axios.get(`${serverUrl}/check_is_inspect.php`);
    const isInspect = response.data.isInspectForWeb;
    const resolve =
      isInspect === "1" ? ServerStatus.Maintenance : ServerStatus.Running;
    return resolve;
  } catch (error) {
    return Promise.reject(error);
  }
}
