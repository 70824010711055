import React, { useCallback } from "react";
import styles from "./OnevocaSearchInput.module.css";
import { debounce } from "lodash";

interface OnevocaSearchInputProps {
  defaultText?: string;
  hint?: string;
  handleSearch: (searchText: string) => void;
}

export default function OnevocaSearchInput(props: OnevocaSearchInputProps) {
  const debouncedHandleSearch = useCallback(
    debounce((value: string) => {
      props.handleSearch(value);
    }, 300),
    [props.handleSearch]
  );

  function handleInputValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    debouncedHandleSearch(e.target.value);
  }

  return (
    <>
      <div className={styles.container}>
        <img className={styles.searchImage} src="iconImgAllSearch.png" />
        <input
          value={props.defaultText}
          className={styles.searchInput}
          placeholder={props.hint}
          onChange={handleInputValueChange}
        />
      </div>
    </>
  );
}
