import { serverUrl } from "../common/contants";
import { getToken } from "../auth/AuthManager";
import { SortType } from "../settings/SortType";
import { convertSortTypeString } from "./terms";
import { TermLevel } from "./TermLevel";
import Term from "./Term";
import OnevocaServerError from "../common/OnevocaServerError";
import getApiInstance from "../common/api";
import Group from "../groups/Group";

export async function fetchTerms(
  seed: number,
  sort?: SortType
): Promise<Term[]> {
  const parameters = {
    seed: seed,
    sort: sort === undefined ? null : convertSortTypeString(sort),
  };

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      `/word/get_word_list_v3.php`,
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      if (response.data.data === undefined) {
        return [];
      }
      const termsData = response.data.data;
      const terms = termsData.map((termData: any) => Term.fromJson(termData));
      return terms;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateTermLevel(
  term: Term,
  toLevel: TermLevel
): Promise<void> {
  const parameters = {
    id: term.id,
    newLevel: toLevel,
    after108v: true,
  };

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      `/word/edit_level.php`,
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateTerm(term: Term): Promise<void> {
  const parameters = {
    id: term.id,
    n_word: term.term,
    n_mean: term.definition,
    n_desc: term.memo,
    n_pron: term.pronunciation,
    n_group: term.group,
  };

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      `/word/edit.php`,
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteTerms(toTerms: Term[]): Promise<void> {
  const parameters: { [key: string]: any } = {};
  toTerms.forEach((term, i) => {
    parameters[`id${i}`] = term.id;
  });

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      "/word/remove_selected.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateTermsLevel(
  toTerms: Term[],
  toLevel: TermLevel
): Promise<void> {
  const parameters: { [key: string]: any } = {};
  parameters["newLevel"] = toLevel;
  parameters["after108v"] = true;
  toTerms.forEach((term, i) => {
    parameters[`id${i}`] = term.id;
  });

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      "/word/edit_level_selected.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateTermsGroup(
  toTerms: Term[],
  toGroup: Group
): Promise<void> {
  const parameters: { [key: string]: any } = {};
  parameters["ngn"] = toGroup.title;
  toTerms.forEach((term, i) => {
    parameters[`id${i}`] = term.id;
  });

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      "/word/edit_group_selected.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteTerm(term: Term): Promise<void> {
  const parameters = {
    id: term.id,
  };

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      `${serverUrl}/word/remove.php`,
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

function makeInsertTermsParameters(terms: Term[]): { [key: string]: any } {
  const termParameters: { [key: string]: any } = {};
  terms.forEach((term, i) => {
    termParameters[`term${i}`] = term.term;
    termParameters[`defi${i}`] = term.definition;
    termParameters[`memo${i}`] = term.memo;
    termParameters[`pron${i}`] = term.pronunciation;
  });
  return termParameters;
}

function makeInsertTermsIdParameters(terms: Term[]): { [key: string]: any } {
  const termParameters: { [key: string]: any } = {};
  terms.forEach((term, i) => {
    termParameters[`id${i}`] = term.id;
  });
  return termParameters;
}

export async function insertTerms(
  terms: Term[],
  toGroup: string
): Promise<void> {
  const parameters = {
    group: toGroup,
    terms: makeInsertTermsParameters(terms),
  };

  try {
    const token = await getToken();
    const response = await getApiInstance(token).post(
      "/word/insertTermList.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getTermsCount(): Promise<number> {
  try {
    const token = await getToken();
    const response = await getApiInstance(token).get("/word/getTermsCount.php");

    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    }

    const count = response.data.count;
    if (count !== undefined && typeof count === "number") {
      return count;
    } else {
      return Promise.reject(new Error("?"));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
