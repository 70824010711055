import { PronunciationPosition } from "../settings/PronunciationPosition";
import { getLearningPronunciationPositionFromLocalStroage } from "../settings/learningSettings";

export function isShowMemo(value: string, front: "front" | "back"): boolean {
  return value.length !== 0 && front !== "front";
}

export function isShowPronunciation(
  value: string,
  front: "front" | "back",
  firstQuestion: "term" | "definition"
): boolean {
  if (value.length === 0) {
    return false;
  }

  switch (firstQuestion) {
    case "term":
      if (
        getLearningPronunciationPositionFromLocalStroage() ===
        PronunciationPosition.onTerm
      ) {
        if (front === "front") {
          return true;
        }
      } else {
        if (front === "back") {
          return true;
        }
      }
      break;
    case "definition":
      if (
        getLearningPronunciationPositionFromLocalStroage() ===
        PronunciationPosition.onTerm
      ) {
        if (front === "back") {
          return true;
        }
      } else {
        if (front === "front") {
          return true;
        }
      }
      break;
  }
  return false;
}
