import axios from "axios";

const baseUrl = "https://texttospeech.googleapis.com/v1beta1";

const ttsApi = axios.create({
  baseURL: baseUrl,
  headers: {
    "X-Goog-Api-Key": process.env.REACT_APP_GOOGLE_API_KEY,
    "Content-Type": "application/json; charset=utf-8",
  },
});

export async function fetchAudio(
  text: string,
  languageCode: string,
  voiceActor: string
): Promise<string> {
  const voiceParameters = {
    name: voiceActor,
    languageCode: languageCode,
  };

  const parameters = {
    input: {
      text: text,
    },
    voice: voiceParameters,
    audioConfig: {
      audioEncoding: "LINEAR16",
    },
  };

  const response = await ttsApi.post("/text:synthesize", parameters);
  const content = response.data.audioContent;
  if (content === undefined) {
    return Promise.reject("Wrong response");
  } else {
    return content;
  }
}
