import { useEffect, useState } from "react";
import Term from "../term/Term";
import styles from "./LearningSelectScreen.module.css";
import { LearningType } from "./LearningType";
import LearningTypeCell from "./LearningTypeCell";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LearningOptions from "./LearningOptions";
import Group from "../groups/Group";
import { TermLevel } from "../term/TermLevel";
import { SortType } from "../settings/SortType";
import { getFilteredTermsWithOptions } from "../term/terms";
import {
  getLearningSelectedGroupsFromLocalStorage,
  getLearningSelectedLevelFromLocalStroage,
  setLearningSelectedGroupsToLocalStroage,
  setLearningSelectedLevelsToLocalStroage,
  setLearningSortTypeToLocalStorage,
} from "../settings/learningSettings";
import { useTerms } from "../term/useTerms";

export default function LearningSelectScreen() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const terms = useTerms();

  const [filteredTerms, setFilteredTerms] = useState<Term[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<TermLevel[]>([]);
  const [sortType, setSortType] = useState(SortType.random);

  const [openLearningSettings, setOpenLearningSettings] =
    useState<LearningType | null>(null);

  useEffect(() => {
    setSelectedGroups(getLearningSelectedGroupsFromLocalStorage());
    setSelectedLevels(getLearningSelectedLevelFromLocalStroage());
  }, []);

  useEffect(() => {
    updateFilteredList();
  }, [selectedGroups, selectedLevels, sortType]);

  function updateFilteredList() {
    setFilteredTerms(
      getFilteredTermsWithOptions(
        terms,
        undefined,
        sortType,
        selectedGroups,
        selectedLevels
      )
    );
  }

  function handleOnClickLearningType(type: LearningType) {
    setOpenLearningSettings(type);
  }

  function startLearning(learningType: LearningType) {
    navigate("/learning", {
      state: { learningType: learningType },
    });
  }

  return (
    <>
      <div className={styles.stack}>
        <div className={styles.screen}>
          <div className={styles.learningItemContainer}>
            <LearningTypeCell
              type={LearningType.flashcards}
              title={t("Flashcard")}
              description={t("FlashcardsExplain")}
              image="iconImgStudyFlash.png"
              onClick={() => handleOnClickLearningType(LearningType.flashcards)}
            />
            <LearningTypeCell
              type={LearningType.multiplechoice}
              title={t("MultipleChoiceTitle")}
              description={t("MultipleChoiceExplain")}
              image="iconImgStudy1For4.png"
              onClick={() =>
                handleOnClickLearningType(LearningType.multiplechoice)
              }
            />
            <LearningTypeCell
              type={LearningType.spellingQuiz}
              title={t("SpellingQuiz")}
              description={t("SpellQuizExplain")}
              image="iconImgStudySpelling.png"
              onClick={() =>
                handleOnClickLearningType(LearningType.spellingQuiz)
              }
            />
          </div>
        </div>
        <LearningOptions
          open={openLearningSettings !== null}
          terms={filteredTerms}
          type={openLearningSettings}
          mode="beforeLearning"
          onUpdateSelectedGroups={(groups: Group[]) => {
            setLearningSelectedGroupsToLocalStroage(groups);
            setSelectedGroups(groups);
          }}
          onUpdateSelectedLevels={(levels: TermLevel[]) => {
            setLearningSelectedLevelsToLocalStroage(levels);
            setSelectedLevels(levels);
          }}
          onUpdateSortType={(sortType: SortType) => {
            setLearningSortTypeToLocalStorage(sortType);
            setSortType(sortType);
          }}
          onClickStartButton={startLearning}
          onClose={() => {
            document.body.style.overflow = "auto";
            setOpenLearningSettings(null);
          }}
        />
      </div>
    </>
  );
}
