import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import PlaceHolderTextField from "../common/PlaceHolderTextField";
import styles from "./ResetPasswordScreen.module.css";
import BigButton from "../common/BigButton";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../configurations/firebaseConfig";
import { getFirebaseErrorMessage } from "../common/FirebaseErrorConverter";
import { FirebaseError } from "firebase/app";
import { Status } from "../common/Status";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../common/LoadingScreen";
import { ServerStatus, fetchServerStatus } from "../common/serverStatus";
import { onAuthStateChanged } from "firebase/auth";
import { logout } from "./AuthManager";

function ResetPasswordScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [serverStatus, setServerStatus] = useState<ServerStatus>();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordResetLinkSendStatus, setPasswordResetLinkSendStatus] =
    useState<Status>(Status.Ready);

  const handleEmailChange = (email: string) => {
    setEmail(email);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkServerInspect();
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (serverStatus !== undefined && serverStatus !== null) {
      switch (serverStatus) {
        case ServerStatus.Maintenance:
          navigate("/maintenance");
          break;
        case ServerStatus.Running:
          break;
      }
    }
  }, [serverStatus]);

  async function checkServerInspect() {
    try {
      setServerStatus(await fetchServerStatus());
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  async function updatePasswordResetLinkSendStatus(status: Status) {
    setPasswordResetLinkSendStatus(status);
    switch (status) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        alert(t("signin_sent_email_tosetpassword"));
        navigate("/login/email");
        break;
    }
  }

  async function handleClickResetLinkButton() {
    updatePasswordResetLinkSendStatus(Status.Loading);
    try {
      await sendPasswordResetEmail(auth, email);
      updatePasswordResetLinkSendStatus(Status.Done);
    } catch (error) {
      if (error instanceof FirebaseError) {
        alert(getFirebaseErrorMessage(error, t));
      }
      updatePasswordResetLinkSendStatus(Status.Ready);
    }
  }

  return (
    <div className={styles.stack}>
      <div className={styles.screen}>
        <div className={styles.container}>
          <PlaceHolderTextField
            text=""
            hint={t("signin_set_password_content_title")}
            lineStyle="single"
            handleTextChange={handleEmailChange}
          />
          <BigButton
            title={t("SendResetPasswordEmail")}
            style={email.length === 0 ? "disable" : "normal"}
            handleClick={() =>
              email.length !== 0 &&
              passwordResetLinkSendStatus !== Status.Loading
                ? handleClickResetLinkButton()
                : null
            }
          />
        </div>
      </div>
      {isLoading === true ? <LoadingScreen /> : null}
    </div>
  );
}

export default ResetPasswordScreen;
