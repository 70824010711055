export function formatNumberWithCommas(value: number | string): string {
  let numberValue: number;

  if (typeof value === "string") {
    numberValue = parseFloat(value.replace(/,/g, ""));
    if (isNaN(numberValue)) {
      console.error(`Invalid input: ${value} is not a valid number.`);
      return "";
    }
  } else {
    numberValue = value;
  }

  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(numberValue);
}

export function normalizeString(str: string): string {
  return str
    .toLowerCase() // 소문자로 변환
    .replace(/\s+/g, ""); // 모든 공백 제거
  // .replace(/[^\w]/g, ""); // 특수문자 제거
}
