import BigButton from "../common/BigButton";
import PlaceHolderTextField from "../common/PlaceHolderTextField";
import TextButton from "../common/TextButton";
import styles from "./EmailSignUpScreen.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  createUserWithEmail,
  logout,
  sendAuthEmail,
  updateDisplayName,
} from "./AuthManager";
import { FirebaseError } from "firebase/app";
import { getFirebaseErrorMessage } from "../common/FirebaseErrorConverter";
import { Status } from "../common/Status";
import LoadingScreen from "../common/LoadingScreen";
import { ServerStatus, fetchServerStatus } from "../common/serverStatus";
import { fetchTemporaryName } from "./authAPI";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../configurations/firebaseConfig";

function EmailSignUpScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverStatus, setServerStatus] = useState<ServerStatus>();

  const [createUserStatus, setCreateUserStatus] = useState<Status>(
    Status.Ready
  );

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [isValidTypedInformations, setIsValidTypedInformations] =
    useState<boolean>(false);
  const [emailSendStatus, setEmailSendStatus] = useState<Status>(Status.Ready);
  const [usernameUpdateStatus, setUsernameUpdateStatus] = useState<Status>(
    Status.Ready
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkServerInspect();
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (serverStatus !== undefined && serverStatus !== null) {
      switch (serverStatus) {
        case ServerStatus.Maintenance:
          navigate("/maintenance");
          break;
        case ServerStatus.Running:
          navigate("/");
          break;
      }
    }
  }, [serverStatus]);

  useEffect(() => {
    switch (createUserStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        makeUsername();
        break;
    }
  }, [createUserStatus]);

  useEffect(() => {
    switch (usernameUpdateStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        sendAuth();
        break;
    }
  }, [usernameUpdateStatus]);

  useEffect(() => {
    switch (emailSendStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        navigate("/login/sign-up/auth", { state: { email } });
        break;
    }
  }, [emailSendStatus]);

  useEffect(() => {
    if (
      email.length !== 0 &&
      password.length !== 0 &&
      passwordConfirm.length !== 0
    ) {
      setIsValidTypedInformations(true);
    } else {
      setIsValidTypedInformations(false);
    }
  }, [email, password, passwordConfirm]);

  async function checkServerInspect() {
    try {
      setServerStatus(await fetchServerStatus());
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  const handleEmailChange = (email: string) => {
    setEmail(email);
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
  };

  const handlePasswordConfirmChange = (passwordConfirm: string) => {
    setPasswordConfirm(passwordConfirm);
  };

  function handleClickSendAuthLinkButton() {
    if (checkValidEmailAndPassword() === true) {
      createUser();
    }
  }

  async function sendAuth() {
    setEmailSendStatus(Status.Loading);
    try {
      await sendAuthEmail();
      setEmailSendStatus(Status.Done);
    } catch (error) {
      if (error instanceof FirebaseError) {
        alert(getFirebaseErrorMessage(error, t));
      } else {
        alert(error);
      }
      setEmailSendStatus(Status.Ready);
    }
  }

  function checkValidEmailAndPassword(): boolean {
    if (email.length === 0) {
      alert(t("signup_warning_enter_email"));
      return false;
    } else if (password.length === 0 || passwordConfirm.length === 0) {
      alert(t("signup_warning_enter_password"));
      return false;
    } else if (password !== passwordConfirm) {
      alert(t("signup_warning_miss_password"));
      return false;
    } else {
      return true;
    }
  }

  async function createUser() {
    setCreateUserStatus(Status.Loading);
    try {
      await createUserWithEmail(email, password);
      setCreateUserStatus(Status.Done);
    } catch (error) {
      if (error instanceof FirebaseError) {
        alert(getFirebaseErrorMessage(error, t));
      } else {
        alert(error);
      }
      setCreateUserStatus(Status.Ready);
    }
  }

  async function makeUsername() {
    setUsernameUpdateStatus(Status.Loading);
    try {
      const temporaryName = await fetchTemporaryName();
      await updateDisplayName(temporaryName);
      setUsernameUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setUsernameUpdateStatus(Status.Ready);
    }
  }

  return (
    <div className={styles.stack}>
      <div className={styles.screen}>
        <div />
        <div className={styles.container}>
          <div className={styles.inputContainer}>
            <PlaceHolderTextField
              hint={t("signup_email_address")}
              lineStyle="single"
              handleTextChange={handleEmailChange}
            />
            <PlaceHolderTextField
              hint={t("signup_password")}
              type="password"
              lineStyle="single"
              handleTextChange={handlePasswordChange}
            />
            <PlaceHolderTextField
              hint={t("signup_check_password")}
              type="password"
              lineStyle="single"
              handleTextChange={handlePasswordConfirmChange}
            />
          </div>
          <BigButton
            title={t("SignupButton")}
            style={isValidTypedInformations === true ? "normal" : "disable"}
            handleClick={() =>
              isValidTypedInformations === true
                ? handleClickSendAuthLinkButton()
                : null
            }
          />
        </div>
        <div className={styles.termsOfUseContainer}>
          <TextButton
            title="Term of use"
            textColor="var(--disabled-color)"
            handleClick={() => {}}
          />
          <p className={styles.termsOfUseSpacer}>|</p>
          <TextButton
            title="Privacy Policy"
            textColor="var(--disabled-color)"
            handleClick={() => {}}
          />
        </div>
      </div>
      {isLoading === true ? <LoadingScreen /> : null}
    </div>
  );
}

export default EmailSignUpScreen;
