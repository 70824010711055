import BigButton from "../common/BigButton";
import PlaceHolderTextField from "../common/PlaceHolderTextField";
import TextButton from "../common/TextButton";
import styles from "./EmailLoginScreen.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FirebaseError } from "firebase/app";
import { getFirebaseErrorMessage } from "../common/FirebaseErrorConverter";
import {
  isAuthEmailLogin,
  login,
  logout,
  sendAuthEmail,
  signInWithEmail,
} from "./AuthManager";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUser } from "./authAPI";
import { logIn } from "./authSlice";
import LoadingScreen from "../common/LoadingScreen";
import OnevocaDialog from "../common/OnevocaDialog";
import { OnevocaDialogButton } from "../common/OnevocaDialogButton";
import { Status } from "../common/Status";
import { ServerStatus, fetchServerStatus } from "../common/serverStatus";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../configurations/firebaseConfig";

function EmailLoginScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogButtons, setDialogButtons] = useState<OnevocaDialogButton[]>([]);

  const [serverStatus, setServerStatus] = useState<ServerStatus>();
  const [authResendStatus, setAuthResendStatus] = useState(Status.Ready);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkServerInspect();
        navigate("/");
      } else {
        logout();
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (serverStatus !== undefined && serverStatus !== null) {
      switch (serverStatus) {
        case ServerStatus.Maintenance:
          navigate("/maintenance");
          break;
        case ServerStatus.Running:
          break;
      }
    }
  }, [serverStatus]);

  useEffect(() => {
    switch (authResendStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        alert(t("signup_resend_mail"));
        break;
    }
  }, [authResendStatus]);

  const handleEmailChange = (email: string) => {
    setEmail(email);
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
  };

  function handleClickSignUpButton() {
    navigate("/login/sign-up");
  }

  async function checkServerInspect() {
    try {
      setServerStatus(await fetchServerStatus());
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  async function resendAuthEmail() {
    setAuthResendStatus(Status.Loading);
    try {
      await sendAuthEmail();
      setAuthResendStatus(Status.Done);
    } catch (error) {
      setAuthResendStatus(Status.Ready);
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  async function handleClickLoginButton() {
    try {
      setIsLoading(true);
      await signInWithEmail(email, password);
      await checkEmailVerified();
      await updateUser();
      setIsLoading(false);
      login();
      dispatch(logIn());
    } catch (error) {
      setIsLoading(false);
      if (error instanceof FirebaseError) {
        alert(getFirebaseErrorMessage(error, t));
      } else if (error instanceof Error) {
        setDialogContent(error.message);
        setDialogButtons([
          {
            title: t("Confirmation"),
            style: "normal",
            onClick: () => {
              setOpenDialog(false);
            },
          },
          {
            title: t("signup_resend"),
            style: "solid",
            onClick: () => {
              setOpenDialog(false);
              resendAuthEmail();
            },
          },
        ]);
        setOpenDialog(true);
      }
    }
  }

  async function checkEmailVerified(): Promise<void> {
    const isVerified = await isAuthEmailLogin();
    if (isVerified === true) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(t("signup_you_have_to_verify")));
    }
  }

  function handleClickResetPasswordButton() {
    navigate("/login/reset-password");
  }

  return (
    <div className={styles.stack}>
      <div className={styles.screen}>
        <div />
        <div className={styles.container}>
          <div className={styles.inputContainer}>
            <PlaceHolderTextField
              text=""
              hint={t("email")}
              lineStyle="single"
              handleTextChange={handleEmailChange}
            />
            <PlaceHolderTextField
              text=""
              hint={t("password")}
              type="password"
              lineStyle="single"
              handleTextChange={handlePasswordChange}
            />
          </div>
          <div className={styles.forgotPasswordContainer}>
            <TextButton
              title={t("signin_forgot_pasword")}
              textColor="var(--light-gray-color)"
              withUnderline={true}
              handleClick={() => handleClickResetPasswordButton()}
            />
          </div>
          <div className={styles.loginButtonContainer}>
            <BigButton
              title={t("SignIn")}
              style="normal"
              handleClick={() => handleClickLoginButton()}
            />
          </div>
        </div>
        <div className={styles.signUpButtonContainer}>
          <TextButton
            title={t("signin_email")}
            textColor="var(--main-black-color)"
            withUnderline={true}
            handleClick={() => handleClickSignUpButton()}
          />
        </div>
      </div>
      {isLoading === true ? <LoadingScreen /> : null}
      <OnevocaDialog
        open={openDialog}
        title={null}
        message={dialogContent}
        buttons={dialogButtons}
      />
    </div>
  );
}

export default EmailLoginScreen;
