import styles from "./VoiceLanguageSelectScreen.module.css";
import { useEffect, useState } from "react";
import { getSupportedLanguages } from "./languages";
import Language from "../language/Language";
import VoiceLanguageListCell from "./VoiceLanguageListCell";
import OnevocaSearchInput from "../common/OnevocaSearchInput";
import { cloneDeep } from "lodash";
import {
  getRecentClickedLanguagesFromLocalStroage,
  setRecentClickedLanguagesToLocalStorage,
} from "../settings/voiceSettings";
import { useTranslation } from "react-i18next";

interface VoiceLanguageSelectScreenProps {
  open: boolean;
  handleOnClickLanguage: (language: Language) => void;
  handleOnClose: () => void;
}

export default function VoiceLanguageSelectScreen(
  props: VoiceLanguageSelectScreenProps
) {
  const { t } = useTranslation();

  const [recentClickedLanguages, setRecentClickedLanguages] = useState<
    Language[]
  >([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [languagesToShow, setLanguagesToShow] = useState<Language[]>([]);

  useEffect(() => {
    if (props.open === true) {
      setRecentClickedLanguages(getRecentClickedLanguagesFromLocalStroage());
      setLanguages(getSupportedLanguages());
    }
  }, [props.open]);

  useEffect(() => {
    setLanguagesToShow(cloneDeep(languages));
  }, [languages]);

  function handleOnClickBackground() {
    props.handleOnClose();
  }

  function handleOnClickLanguageSelectContainer(
    e: React.MouseEvent<HTMLDivElement>
  ) {
    e.stopPropagation();
  }

  function handleOnClickCell(language: Language) {
    updateRecentClickedLanguages(language);
    props.handleOnClickLanguage(language);
    props.handleOnClose();
  }

  function updateRecentClickedLanguages(language: Language) {
    const prevLanguages = getRecentClickedLanguagesFromLocalStroage();
    const updatedLanguages = [
      language,
      ...prevLanguages.filter((l) => l.code !== language.code),
    ];
    setRecentClickedLanguagesToLocalStorage(updatedLanguages.slice(0, 3));
  }

  function searchLanguages(searchText: string) {
    const filteredLanguages = languages.filter(
      (language) =>
        language.name.toLowerCase().includes(searchText.toLowerCase()) ||
        language.code.toLowerCase().includes(searchText.toLowerCase())
    );
    setLanguagesToShow(filteredLanguages);
  }

  if (props.open === true) {
    return (
      <>
        <div className={styles.stack}>
          <div
            className={styles.container}
            onClick={() => handleOnClickBackground()}
          >
            <div
              className={styles.languageSelectContainer}
              onClick={handleOnClickLanguageSelectContainer}
            >
              <div className={styles.searchContainer}>
                <OnevocaSearchInput
                  handleSearch={(searchText: string) => {
                    if (
                      searchText !== null &&
                      searchText !== undefined &&
                      searchText.length !== 0
                    ) {
                      searchLanguages(searchText);
                    } else {
                      setLanguagesToShow(cloneDeep(languages));
                    }
                  }}
                />
              </div>
              <div className={styles.listContainer}>
                {recentClickedLanguages !== null &&
                  recentClickedLanguages.length > 0 && (
                    <p className={styles.headerText}>
                      {t("LANGUAGE_SELECT_VC_HEADER_TITLE_RECOMMENDED")}
                    </p>
                  )}
                {recentClickedLanguages !== null &&
                  recentClickedLanguages.length > 0 &&
                  recentClickedLanguages.map((language, index) => (
                    <VoiceLanguageListCell
                      key={index}
                      language={language}
                      onClick={handleOnClickCell}
                    />
                  ))}
                <p className={styles.headerText}>
                  {t("LANGUAGE_SELECT_VC_HEADER_TITLE_ALL")}
                </p>
                {languagesToShow.length > 0 &&
                  languagesToShow.map((language, index) => (
                    <VoiceLanguageListCell
                      key={index}
                      language={language}
                      onClick={handleOnClickCell}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
