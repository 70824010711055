import i18n from "../../i18n";

export const errorAlreadyTermInGroup = "AlreadySaveTerm";
export const errorNoExistData = "NoExistData";
export const errorNeedMoreSlot = "NotEnoughSlot";
export const errorWrongText = "WrongText";
export const errorNoAccessRight = "NoAccessRight";
export const errorAlreadyUsedGroup = "AreadyUsedGroup";
export const errorGroupnameTooShort = "GroupnameTooShort";
export const errorOverUploadCount = "OverUploadCount";
export const errorNoPost = "NoPost";
export const errorShareSubjectTooShort = "ShareSubjectTooShort";
export const errorShareSubjectTooLong = "ShareSubjectTooLong";
export const errorStudyGroupIntroductionTooLong =
  "StudyGroupIntroductionTooLong";
export const errorStudyGroupAlreadyJoined = "StudyGroupAlreadyJoined";
export const errorStudyGroupBlockedJoin = "StudyGroupBlockedJoin";
export const errorNeedSelectLeastOneGroup = "NeedSelectLeastOneGroup";
export const errorStudyGroupNotFound = "StudyGroupNotFound";
export const errorTermTooShort = "TermTooShort";
export const errorTermTooLong = "TermTooLong";
export const errorDefinitionTooShort = "DefinitionTooShort";
export const errorDefinitionTooLong = "DefinitionTooLong";
export const errorMemoTooLong = "MemoTooLong";
export const errorPronunciationTooLong = "PronunciationTooLong";
export const errorNoImageFile = "NoImageFile";
export const errorNoImageFileName = "NoImageFileName";
export const errorImageUploadFailure = "ImageUploadFailure";
export const errorLoginAuthError = "LoginAuthError";

export default class OnevocaServerError extends Error {
  constructor(errorCode: string) {
    let name = "";
    let message = "";
    switch (errorCode) {
      case "1":
        name = errorAlreadyTermInGroup;
        message = i18n.t("Already");
        break;
      case "2":
      case "11":
        name = errorNoExistData;
        message = i18n.t("NoExistData");
        break;
      case "10":
        name = errorAlreadyTermInGroup;
        message = i18n.t("A_Group");
        break;
      case "13":
        name = errorGroupnameTooShort;
        message = i18n.t("group_short_string");
        break;
      case "20":
      case "33":
        name = errorOverUploadCount;
        message = i18n.t("CountLimitDay");
        break;
      case "21":
        name = errorNoPost;
        message = i18n.t("NoPost");
        break;
      case "22":
        name = errorShareSubjectTooShort;
        message = i18n.t("share_sort_subject");
        break;
      case "23":
        name = errorShareSubjectTooLong;
        message = i18n.t("share_long_subject");
        break;
      case "24":
        name = errorStudyGroupIntroductionTooLong;
        message = i18n.t("TeamIntroductionLongLength");
        break;
      case "25":
        name = errorStudyGroupAlreadyJoined;
        message = i18n.t("TeamAlreadyJoined");
        break;
      case "26":
        name = errorStudyGroupBlockedJoin;
        message = i18n.t("TeamBlockedJoin");
        break;
      case "60":
        name = errorNeedSelectLeastOneGroup;
        message = i18n.t("PleaseSelectGroupLeastThenOne");
        break;
      case "70":
        name = errorStudyGroupNotFound;
        message = i18n.t("TeamFoundNot");
        break;
      case "71":
        name = errorTermTooShort;
        message = i18n.t("TermShortMessage");
        break;
      case "72":
        name = errorTermTooLong;
        message = i18n.t("TermLongMessage");
        break;
      case "73":
        name = errorDefinitionTooShort;
        message = i18n.t("DefiShortMessage");
        break;
      case "74":
        name = errorDefinitionTooLong;
        message = i18n.t("DefiLongMessage");
        break;
      case "75":
        name = errorMemoTooLong;
        message = i18n.t("MemoLongMessage");
        break;
      case "76":
        name = errorPronunciationTooLong;
        message = i18n.t("PronLongMessage");
        break;
      case "80":
        name = errorPronunciationTooLong;
        message = i18n.t("NoImageFile");
        break;
      case "81":
        name = errorNoImageFileName;
        message = i18n.t("NoImageFileName");
        break;
      case "82":
        name = errorImageUploadFailure;
        message = i18n.t("ImageFileUploadFailure");
        break;
      case "200":
        name = errorLoginAuthError;
        message = i18n.t("loginerror");
        break;
      case "1000":
        name = errorNeedMoreSlot;
        message = i18n.t("noslotmessage");
        break;
      case "1001":
        name = errorWrongText;
        message = i18n.t("wrong_text");
        break;
      case "1002":
        name = errorNoAccessRight;
        message = i18n.t("NoAccessRight");
        break;
      default:
        name = "Unknown Error.";
        message = errorCode;
        break;
    }

    super(message);
    this.name = name;

    Object.setPrototypeOf(this, OnevocaServerError.prototype);
  }
}
