import classNames from "classnames";
import styles from "./TextButton.module.css";

interface TextButtonProps {
  title: string;
  textColor: string;
  withUnderline?: boolean;
  handleClick: () => void;
}

export default function TextButton(props: TextButtonProps) {
  function handleClick() {
    props.handleClick();
  }

  const textButtonColor = {
    color: props.textColor,
  };

  const buttonTitleClass = classNames(styles.buttonTitle, {
    [styles.buttonTitle]: true,
    [styles.buttonTitleUnderline]: props.withUnderline === true,
  });

  return (
    <>
      <button
        onClick={() => handleClick()}
        className={styles.textButton}
        style={textButtonColor}
      >
        <p className={buttonTitleClass}>{props.title}</p>
      </button>
    </>
  );
}
