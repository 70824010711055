import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { OnevocaDialogButton } from "./OnevocaDialogButton";

interface OnevocaDialogProps {
  open: boolean;
  title: string | null;
  message: string;
  buttons: OnevocaDialogButton[];
}

function OnevocaDialog(props: OnevocaDialogProps) {
  const getColor = (styleType: string) => {
    const colorMap: { [key: string]: string } = {
      normal: "var(--dark-gray-color)",
      solid: "var(--primary-color)",
      delete: "var(--delete-color)",
    };
    return colorMap[styleType] || "inherit";
  };

  return (
    <Dialog open={props.open}>
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.buttons.map((button, index) => (
          <Button
            key={index}
            style={{
              color: getColor(button.style),
            }}
            onClick={button.onClick}
          >
            {button.title}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}

export default OnevocaDialog;
