import { useEffect, useState } from "react";
import PlaceHolderTextField from "./PlaceHolderTextField";
import styles from "./ValueChangeDialog.module.css";
import MediumButton from "./MediumButton";
import { useTranslation } from "react-i18next";

interface ValueChangeDialogProps {
  open: boolean;
  title?: string;
  message?: string;
  value?: string | null;
  hint?: string | null;
  minTextCount: number;
  maxTextCount: number;
  onSave: (value: string) => void;
  onClose: () => void;
}

export default function ValueChangeDialog(props: ValueChangeDialogProps) {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<string>();

  useEffect(() => {
    if (props.open === true) {
      const newValue = props.value;
      if (newValue !== null) {
        setCurrentValue(newValue);
      }
    }
  }, [props.open]);

  function save() {
    if (currentValue === null || currentValue === undefined) {
      return;
    }

    if (
      currentValue.length < props.minTextCount ||
      currentValue.length > props.maxTextCount
    ) {
      return;
    }

    props.onSave(currentValue);
    props.onClose();
  }

  if (props.open === false) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.dialogContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.titleAndMessageContainer}>
              {props.title !== undefined && (
                <p className={styles.titleText}>{props.title}</p>
              )}
              {props.message !== undefined && (
                <p className={styles.messageText}>{props.message}</p>
              )}
            </div>
            <div className={styles.textFieldContainer}>
              <PlaceHolderTextField
                text={currentValue}
                hint={props.hint ? props.hint : undefined}
                minCount={props.minTextCount}
                maxCount={props.maxTextCount}
                lineStyle="single"
                handleTextChange={(text: string) => {
                  setCurrentValue(text);
                }}
              />
            </div>
            <div className={styles.buttonsContainer}>
              <MediumButton
                title={t("Cancel")}
                style="clear"
                onClick={() => props.onClose()}
              />
              <MediumButton
                title={t("Done")}
                style="primary"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
