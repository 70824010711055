import Language from "../language/Language";
import styles from "./VoiceLanguageListCell.module.css";

interface VoiceLanguageListCellProps {
  language: Language;
  onClick: (language: Language) => void;
}

export default function VoiceLanguageListCell(
  props: VoiceLanguageListCellProps
) {
  return (
    <>
      <div
        className={styles.container}
        onClick={() => {
          props.onClick(props.language);
        }}
      >
        <p
          className={styles.languageText}
        >{`${props.language.name} - ${props.language.code}`}</p>
      </div>
    </>
  );
}
