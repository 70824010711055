import { ClipLoader } from "react-spinners";
import styles from "./LoadingScreen.module.css";

function LoadingScreen() {
  return (
    <div className={styles.screen}>
      <ClipLoader loading={true} size={32} color="var(--primary-color)" />
    </div>
  );
}

export default LoadingScreen;
