import classNames from "classnames";
import styles from "./SmallButton.module.css";

interface SmallButtonProps {
  title: string;
  style: "normal" | "disable" | "simple";
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export default function SmallButton(props: SmallButtonProps) {
  const buttonClass = classNames(styles.button, {
    [styles.styleNormal]: props.style === "normal",
    [styles.styleDisable]: props.style === "disable",
    [styles.styleSimple]: props.style === "simple",
  });
  return (
    <>
      <div className={buttonClass} onClick={props.onClick}>
        <p className={styles.title}>{props.title}</p>
      </div>
    </>
  );
}
