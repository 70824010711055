import TextButton from "../common/TextButton";
import styles from "./LoginScreen.module.css";
import LoginButton from "../common/LoginButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  login,
  logout,
  signInWithApple,
  signInWithGoogle,
} from "./AuthManager";
import { updateUser } from "./authAPI";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "./authSlice";
import LoadingScreen from "../common/LoadingScreen";
import { ServerStatus, fetchServerStatus } from "../common/serverStatus";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../configurations/firebaseConfig";

function LoginScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverStatus, setServerStatus] = useState<ServerStatus>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkServerInspect();
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (serverStatus !== undefined && serverStatus !== null) {
      switch (serverStatus) {
        case ServerStatus.Maintenance:
          navigate("/maintenance");
          break;
        case ServerStatus.Running:
          navigate("/");
          break;
      }
    }
  }, [serverStatus]);

  async function checkServerInspect() {
    try {
      setServerStatus(await fetchServerStatus());
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  async function handleClickGoogleLoginButton() {
    try {
      setIsLoading(true);
      await signInWithGoogle();
      await updateUser();
      setIsLoading(false);
      login();
      dispatch(logIn());
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  }

  async function handleClickAppleLoginButton() {
    try {
      setIsLoading(true);
      await signInWithApple();
      await updateUser();
      setIsLoading(false);
      login();
      dispatch(logIn());
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  }

  function handleClickEmailLoginButton() {
    navigate("/login/email");
  }

  function handleClickSignUpButton() {
    navigate("/login/sign-up");
  }

  function handleOnClickTermsOfUseButton() {
    window.open(
      "https://scarlet-deposit-885.notion.site/901e75020d864469a28d38a87b00a0c4?pvs=4"
    );
  }

  function handleOnClickPrivacyPolicyButton() {
    window.open(
      "https://scarlet-deposit-885.notion.site/520dd184da364cbd8ce010699d20d0ee?pvs=4"
    );
  }

  return (
    <div className={styles.stack}>
      <div className={styles.loginScreen}>
        <div className={styles.loginContainer}>
          <div className={styles.logoContainer}>
            <div className="onevocaLogo">
              <img src="imgOnevocaLogoBlue.png" width="224px" height="68px" />
            </div>
          </div>
          <div className={styles.loginButtonContainer}>
            <LoginButton
              title={t("signin_google")}
              image="iconImgLoginGoogle.png"
              imageColor="#323232"
              borderType="solid"
              titleColor="var(--main-black-color)"
              backgroundColor="#00000000"
              handleClick={() => handleClickGoogleLoginButton()}
            />
            <LoginButton
              title={t("signin_apple")}
              image="iconImgLoginApple.png"
              borderType="none"
              titleColor="#FFFFFF"
              handleClick={() => handleClickAppleLoginButton()}
              backgroundColor="#333333"
            />
            <LoginButton
              title={t("signin_whith_email")}
              image="iconImgLoginEmail.png"
              borderType="none"
              titleColor="#FFFFFF"
              backgroundColor="var(--primary-color)"
              handleClick={() => handleClickEmailLoginButton()}
            />
            <div className={styles.signUpContainer}>
              <TextButton
                title={t("signin_email")}
                textColor="var(--main-black-color"
                withUnderline={true}
                handleClick={() => handleClickSignUpButton()}
              />
            </div>
          </div>
        </div>
        <div className={styles.termsOfUseContainer}>
          <TextButton
            title={t("TermsOfUse")}
            textColor="var(--disabled-color)"
            handleClick={() => handleOnClickTermsOfUseButton()}
          />
          <p className={styles.termsOfUseSpacer}>|</p>
          <TextButton
            title={t("PrivacyPolicy")}
            textColor="var(--disabled-color)"
            handleClick={() => handleOnClickPrivacyPolicyButton()}
          />
        </div>
      </div>
      {isLoading === true ? <LoadingScreen /> : null}
    </div>
  );
}

export default LoginScreen;
