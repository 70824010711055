const mySlotCountKey = "MySlotCount";
const myTermsCountKey = "MyTermsCount";
const profileImageLastUpdatedKey = "ProfileImageLastUpdatedKey";

export function getSlotsFromLocalStorage(): string {
  const slots = localStorage.getItem(mySlotCountKey);
  if (slots === null || slots === undefined) {
    return "Error";
  } else {
    return slots;
  }
}

export function setSlotsToLocalStorage(slots: string) {
  localStorage.setItem(mySlotCountKey, slots);
}

export function getMyTermsCountFromLocalStroage(): string {
  const count = localStorage.getItem(myTermsCountKey);
  if (count === null || count === undefined) {
    return "Error";
  } else {
    return count;
  }
}

export function setMyTermsCountToLocalStorage(count: string) {
  localStorage.setItem(myTermsCountKey, count);
}

export function getLastUpdatedProfileImage() {
  const date = localStorage.getItem(profileImageLastUpdatedKey);
  if (date === null || date === undefined) {
    const nowDate = Date.now().toString();
    setLastUpdatedProfileImage(nowDate);
    return nowDate;
  } else {
    return date;
  }
}

export function setLastUpdatedProfileImage(date: string) {
  localStorage.setItem(profileImageLastUpdatedKey, date);
}
