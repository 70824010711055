import Language from "../language/Language";
import { getLanguageFromCode } from "../voice/languages";

export default class Group {
  title: string;
  termCount: number;
  language?: Language | undefined;
  learningPercentange: number;
  selected: boolean = false;

  constructor(
    title: string,
    termCount: number,
    language: string,
    learningPercentange: number
  ) {
    this.title = title;
    this.termCount = termCount;
    this.language = getLanguageFromCode(language);
    this.learningPercentange = learningPercentange;
  }

  static fromJson(json: any): Group {
    return new Group(json.gn, json.cn, json.lang, json.per);
  }
}
