import { useEffect, useState } from "react";
import Term, {
  definitionMaxSize,
  getGroupString,
  memoMaxSize,
  pronunciationMaxSize,
  termMaxSize,
} from "./Term";
import styles from "./TermEditScreen.module.css";
import cloneDeep from "lodash/cloneDeep";
import PlaceHolderTextField from "../common/PlaceHolderTextField";
import BigButton from "../common/BigButton";
import GroupSelectScreen from "../groups/GroupSelectScreen";
import { useTranslation } from "react-i18next";
import Group from "../groups/Group";

interface TermEditScreenProps {
  open: boolean;
  term: Term;
  handleOnSave: (term: Term) => void;
  handleOnClose: () => void;
}

export default function TermEditScreen(props: TermEditScreenProps) {
  const { t } = useTranslation();

  const [termToSave, setTermToSave] = useState<Term>();
  const [showGroupSelect, setShowGroupSelect] = useState(false);

  useEffect(() => {
    setTermToSave(cloneDeep(props.term));
  }, [props.term]);

  function handleOnClickBackground() {
    props.handleOnClose();
  }

  function handleOnClickTermEditContainer(
    event: React.MouseEvent<HTMLDivElement>
  ) {
    event.stopPropagation();
  }

  function handleOnClickCancelButton() {
    props.handleOnClose();
  }

  function handleOnClickSaveButton() {
    if (termToSave !== undefined && termToSave !== null) {
      props.handleOnSave(termToSave);
    }
    props.handleOnClose();
  }

  function handleOnClickGroupButton() {
    setShowGroupSelect(true);
  }

  function handleTermValueChange(value: string) {
    const newTerm = cloneDeep(termToSave);
    if (newTerm !== undefined) {
      newTerm.term = value;
      setTermToSave(newTerm);
    }
  }

  function handleDefinitionValueChange(value: string) {
    const newTerm = cloneDeep(termToSave);
    if (newTerm !== undefined) {
      newTerm.definition = value;
      setTermToSave(newTerm);
    }
  }

  function handleMemoValueChange(value: string) {
    const newTerm = cloneDeep(termToSave);
    if (newTerm !== undefined) {
      newTerm.memo = value;
      setTermToSave(newTerm);
    }
  }

  function handlePronunciationValueChange(value: string) {
    const newTerm = cloneDeep(termToSave);
    if (newTerm !== undefined) {
      newTerm.pronunciation = value;
      setTermToSave(newTerm);
    }
  }

  function updateGroup(group: string) {
    const newTerm = cloneDeep(termToSave);
    if (newTerm !== undefined) {
      newTerm.group = group;
      setTermToSave(newTerm);
    }
  }

  if (props.open === true) {
    return (
      <>
        <div className={styles.stack}>
          <div
            className={styles.container}
            onClick={() => handleOnClickBackground()}
          >
            <div
              className={styles.termEditContainer}
              onClick={handleOnClickTermEditContainer}
            >
              <div className={styles.contentContainer}>
                <div className={styles.contentTitleAndContentContainer}>
                  <p className={styles.contentTitle}>{t("Word")}</p>
                  <PlaceHolderTextField
                    text={termToSave?.term}
                    maxCount={termMaxSize}
                    lineStyle="multiple"
                    handleTextChange={handleTermValueChange}
                  />
                </div>
                <div className={styles.contentTitleAndContentContainer}>
                  <p className={styles.contentTitle}>{t("Meaning")}</p>
                  <PlaceHolderTextField
                    text={termToSave?.definition}
                    maxCount={definitionMaxSize}
                    lineStyle="multiple"
                    handleTextChange={handleDefinitionValueChange}
                  />
                </div>
                <div className={styles.contentTitleAndContentContainer}>
                  <p className={styles.contentTitle}>{t("Description")}</p>
                  <PlaceHolderTextField
                    text={termToSave?.memo}
                    maxCount={memoMaxSize}
                    lineStyle="multiple"
                    handleTextChange={handleMemoValueChange}
                  />
                </div>
                <div className={styles.contentTitleAndContentContainer}>
                  <p className={styles.contentTitle}>{t("Pronunciation")}</p>
                  <PlaceHolderTextField
                    text={termToSave?.pronunciation}
                    maxCount={pronunciationMaxSize}
                    lineStyle="multiple"
                    handleTextChange={handlePronunciationValueChange}
                  />
                </div>
                <div className={styles.groupContainer}>
                  <p className={styles.contentTitle}>Group</p>
                  <p
                    className={styles.groupSelectValue}
                    onClick={() => handleOnClickGroupButton()}
                  >
                    {termToSave !== undefined && termToSave !== null
                      ? getGroupString(termToSave.group)
                      : ""}
                  </p>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <BigButton
                  title={t("Cancel")}
                  style="disable"
                  handleClick={() => handleOnClickCancelButton()}
                />
                <BigButton
                  title={t("Save")}
                  style="normal"
                  handleClick={() => handleOnClickSaveButton()}
                />
              </div>
            </div>
          </div>
          <GroupSelectScreen
            open={showGroupSelect === true}
            selectStyle="single"
            showAllGroup={false}
            showNoGroup={true}
            showGroupAddButton={true}
            handleOnSelectGroups={(groups: Group[]) => {
              if (groups.length !== 0) {
                const group = groups[0];
                updateGroup(group.title);
              }
            }}
            handleOnClose={() => {
              setShowGroupSelect(false);
            }}
          />
        </div>
      </>
    );
  } else {
    return null;
  }
}
