import styles from "./BusinessContainer.module.css";
import { useTranslation } from "react-i18next";
import { Instagram } from "@mui/icons-material";
import { auth } from "../../configurations/firebaseConfig";

export default function BusinessContainer() {
  const { t } = useTranslation();

  function handleOnClickPrivacyPolicyButton() {
    window.open(
      "https://scarlet-deposit-885.notion.site/520dd184da364cbd8ce010699d20d0ee?pvs=4"
    );
  }

  function handleOnClickTermsOfUseButton() {
    window.open(
      "https://scarlet-deposit-885.notion.site/901e75020d864469a28d38a87b00a0c4?pvs=4"
    );
  }

  function handleOnClickEmail() {
    const uid = auth.currentUser?.providerData[0].uid;
    const email = "zak1ller.kr@gmail.com";
    const subject = "Onevoca Web Version";
    const body = `Onevoca ID: ${uid}\n\n`;
    const link = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = link;
  }

  function handleOnClickInstagramButton() {
    window.open("https://www.instagram.com/m_i_n_th/");
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <p className={styles.companyNameText}>MNIS</p>
          <p className={styles.companyRegistrationNumberText}>
            {`${t("BusinessRegistrationNumber")} : 8282600960 | ${t(
              "CommerceBusinessRegistrationNumber"
            )} : 제 2020-부산부산진-0230 | ${t("Representative")} : ${t("Name")}
             | 070-8983-9365`}
          </p>
          <p className={styles.companyAddressText}>{t("Address")}</p>
          <p
            className={styles.contactAddressText}
            onClick={() => handleOnClickEmail()}
          >
            {`${t("ContactUs")} : zak1ller.kr@gmail.com`}
          </p>
          <div className={styles.privacyPolicyContainer}>
            <button
              className={styles.buttonText}
              onClick={() => handleOnClickTermsOfUseButton()}
            >
              {t("TermsOfUse")}
            </button>
            <button
              className={styles.buttonText}
              onClick={() => handleOnClickPrivacyPolicyButton()}
            >
              {t("PrivacyPolicy")}
            </button>
          </div>
          <div className={styles.socialLinkButtonContainer}>
            <Instagram
              onClick={() => handleOnClickInstagramButton()}
              style={{
                fontSize: 32,
                cursor: "pointer",
                color: "var(--main-black-color)",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
