import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TopNavigation.module.css";
import { logout } from "../auth/AuthManager";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../configurations/firebaseConfig";
import TabButton from "./TabButton";
import Divider from "./Divider";
import ProfileIcon from "../auth/ProfileIcon";
import { profileImageUrl } from "./contants";
import { getLastUpdatedProfileImage } from "../settings/userSettings";
import { useTranslation } from "react-i18next";
import { MenuRounded } from "@mui/icons-material";

interface TopNavigationProps {
  handleClickToggleLeftBarButton: () => void;
  handleClickOnevocaLogo: () => void;
  handleClickUserProfileIcon: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleClickLogOutButton: () => void;
  handleClickTab: (title: string) => void;
  showTab: boolean;
  showUserIcon: boolean;
}

export default function TopNavigation(props: TopNavigationProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [profileIconSrc, setProfileIconSrc] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setProfileIconSrc(
          `${profileImageUrl}${
            user.providerData[0].uid
          }.jpg?t=${getLastUpdatedProfileImage()}`
        );
      } else {
        logout();
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  function handleClickTab(tab: string) {
    props.handleClickTab(tab);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.topNavigationScreen}>
          <div className={styles.leftButtonsContainer}>
            <div onClick={() => props.handleClickToggleLeftBarButton()}>
              <MenuRounded className={styles.leftBarToggleButton} />
            </div>
            <img
              className={styles.onevocaLogo}
              src="imgOnevocaLogo.png"
              onClick={() => props.handleClickOnevocaLogo()}
            />
          </div>
          {/* {props.showTab === true && (
            <div className={styles.menuTabContainer}>
              <TabButton
                title={t("TermListTitle")}
                handleClick={() => handleClickTab("home")}
              />
              <TabButton
                title={t("LearningTitle")}
                handleClick={() => handleClickTab("learning")}
              />
            </div>
          )} */}
          <div className={styles.space}></div>
          {props.showUserIcon === true && (
            <div className={styles.userContainer}>
              <ProfileIcon
                size="40px"
                src={profileIconSrc}
                onCursorPoint={true}
                onClick={props.handleClickUserProfileIcon}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
