import i18n from "../../i18n";
import Language from "../language/Language";

export enum SupportedLanguages {
  en = "en",
  da = "da",
  nl = "nl",
  fr = "fr",
  de = "de",
  it = "it",
  ja = "ja",
  ko = "ko",
  nb = "nb",
  pl = "pl",
  pt = "pt",
  ru = "ru",
  sk = "sk",
  es = "es",
  sv = "sv",
  tr = "tr",
  uk = "uk",
  zh = "zh",
  ar = "ar",
  cs = "cs",
  hi = "hi",
  hu = "hu",
  id = "id",
  vi = "vi",
  bn = "bn",
  fil = "fil",
  fi = "fi",
  el = "el",
  gu = "gu",
  he = "he",
  kn = "kn",
  ms = "ms",
  ml = "ml",
  mr = "mr",
  pa = "pa",
  ro = "ro",
  ta = "ta",
}

export const supportedLanguages: SupportedLanguages[] = [
  SupportedLanguages.en,
  SupportedLanguages.da,
  SupportedLanguages.nl,
  SupportedLanguages.fr,
  SupportedLanguages.de,
  SupportedLanguages.it,
  SupportedLanguages.ja,
  SupportedLanguages.ko,
  SupportedLanguages.nb,
  SupportedLanguages.pl,
  SupportedLanguages.pt,
  SupportedLanguages.ru,
  SupportedLanguages.sk,
  SupportedLanguages.es,
  SupportedLanguages.sv,
  SupportedLanguages.tr,
  SupportedLanguages.uk,
  SupportedLanguages.zh,
  SupportedLanguages.ar,
  SupportedLanguages.cs,
  SupportedLanguages.hi,
  SupportedLanguages.hu,
  SupportedLanguages.id,
  SupportedLanguages.vi,
  SupportedLanguages.bn,
  SupportedLanguages.fil,
  SupportedLanguages.fi,
  SupportedLanguages.el,
  SupportedLanguages.gu,
  SupportedLanguages.he,
  SupportedLanguages.kn,
  SupportedLanguages.ms,
  SupportedLanguages.ml,
  SupportedLanguages.mr,
  SupportedLanguages.pa,
  SupportedLanguages.ro,
  SupportedLanguages.ta,
];

export function getSupportedLanguages(): Language[] {
  let returnList = supportedLanguages.map(
    (lang) => new Language(lang, translateSupportedLanguage(lang))
  );

  returnList.sort((a, b) => a.name.localeCompare(b.name));
  return returnList;
}

export function getLanguageFromCode(code: string): Language | undefined {
  const language = getSupportedLanguages().find(
    (language) => language.code === code
  );
  return language;
}

export function translateSupportedLanguage(language: SupportedLanguages) {
  const translations: { [key in SupportedLanguages]: string } = {
    [SupportedLanguages.en]: i18n.t("en"),
    [SupportedLanguages.da]: i18n.t("da"),
    [SupportedLanguages.nl]: i18n.t("nl"),
    [SupportedLanguages.fr]: i18n.t("fr"),
    [SupportedLanguages.de]: i18n.t("de"),
    [SupportedLanguages.it]: i18n.t("it"),
    [SupportedLanguages.ja]: i18n.t("ja"),
    [SupportedLanguages.ko]: i18n.t("ko"),
    [SupportedLanguages.nb]: i18n.t("nb"),
    [SupportedLanguages.pl]: i18n.t("pl"),
    [SupportedLanguages.pt]: i18n.t("pt"),
    [SupportedLanguages.ru]: i18n.t("ru"),
    [SupportedLanguages.sk]: i18n.t("sk"),
    [SupportedLanguages.es]: i18n.t("es"),
    [SupportedLanguages.sv]: i18n.t("sv"),
    [SupportedLanguages.tr]: i18n.t("tr"),
    [SupportedLanguages.uk]: i18n.t("uk"),
    [SupportedLanguages.zh]: i18n.t("zh"),
    [SupportedLanguages.ar]: i18n.t("ar"),
    [SupportedLanguages.cs]: i18n.t("cs"),
    [SupportedLanguages.hi]: i18n.t("hi"),
    [SupportedLanguages.hu]: i18n.t("hu"),
    [SupportedLanguages.id]: i18n.t("id"),
    [SupportedLanguages.vi]: i18n.t("vi"),
    [SupportedLanguages.bn]: i18n.t("bn"),
    [SupportedLanguages.fil]: i18n.t("fil"),
    [SupportedLanguages.fi]: i18n.t("fi"),
    [SupportedLanguages.el]: i18n.t("el"),
    [SupportedLanguages.gu]: i18n.t("gu"),
    [SupportedLanguages.he]: i18n.t("he"),
    [SupportedLanguages.kn]: i18n.t("kn"),
    [SupportedLanguages.ms]: i18n.t("ms"),
    [SupportedLanguages.ml]: i18n.t("ml"),
    [SupportedLanguages.mr]: i18n.t("mr"),
    [SupportedLanguages.pa]: i18n.t("pa"),
    [SupportedLanguages.ro]: i18n.t("ro"),
    [SupportedLanguages.ta]: i18n.t("ta"),
  };

  return translations[language];
}
