export function generateRandomOrderNumber(length: number): string {
  let orderNumber = "";
  for (let i = 0; i < length; i++) {
    const randomDigit = Math.floor(Math.random() * 10);
    orderNumber += randomDigit.toString();
  }
  return orderNumber;
}

function seededRandom(seed: number): () => number {
  return function () {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
}

export function seededShuffle<T>(array: T[], seed: number): T[] {
  const rng = seededRandom(seed);
  const result = [...array];

  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(rng() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }

  return result;
}

export function generateRandomSeed(
  min: number = 1,
  max: number = 999999
): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
