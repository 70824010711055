import classNames from "classnames";
import styles from "./MultipleChoiceAnswerButton.module.css";
import { useEffect, useRef, useState } from "react";

interface MultipleChoiceAnswerButtonProps {
  isAnswer: boolean;
  value: string;
  isResultMode: boolean;
  onClick: (isAnswer: boolean) => void;
}

export function MultipleChoiceAnswerButton(
  props: MultipleChoiceAnswerButtonProps
) {
  const [isClicked, setIsClicked] = useState(false);

  const containerClass = classNames(styles.container, {
    [styles.containerCorrect]: props.isResultMode === true && props.isAnswer,
    [styles.containerIncorrect]:
      props.isResultMode === true && !props.isAnswer && isClicked,
  });

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.borderColor = props.isResultMode
        ? ""
        : "var(--button-color)";
    }
  }, [props.isResultMode]);

  function handleOnClick() {
    if (props.value === undefined || props.isResultMode) {
      return;
    }
    setIsClicked(true);
    props.onClick(props.isAnswer);
  }

  return (
    <>
      <div
        ref={containerRef}
        className={containerClass}
        onClick={() => handleOnClick()}
        onMouseEnter={(e) => {
          (e.currentTarget as HTMLDivElement).style.borderColor =
            !props.isResultMode ? "var(--sub-light-gray-color)" : "";
        }}
        onMouseLeave={(e) => {
          (e.currentTarget as HTMLDivElement).style.borderColor = "";
        }}
      >
        <p className={styles.value}>{props.value}</p>
      </div>
    </>
  );
}
