import styles from "./AccountSettingScreen.module.css";
import LoadingScreen from "../common/LoadingScreen";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TopNavigation from "../common/TopNavigation";
import BusinessContainer from "../common/BusinessContainer";
import ProfileIcon from "./ProfileIcon";
import { onAuthStateChanged } from "firebase/auth";
import SmallButton from "../common/SmallButton";
import { deleteCurrentUser, logout } from "./AuthManager";
import { auth } from "../../configurations/firebaseConfig";
import BasicMenu, { BasicMenuButton } from "../common/BasicMenu";
import { updateProfileImage as updateProfileImageFromAPI } from "./authAPI";
import { deleteProfileImage as deleteProfileImageFromAPI } from "./authAPI";
import { updateUsername as updateUsernameFromAPI } from "./authAPI";
import { deleteUser as deleteUserFromAPI } from "./authAPI";
import { convertFileToJpg } from "../common/files";
import { Status } from "../common/Status";
import { Snackbar } from "@mui/material";
import { profileImageUrl } from "../common/contants";
import Divider from "../common/Divider";
import {
  getLastUpdatedProfileImage,
  setLastUpdatedProfileImage,
} from "../settings/userSettings";
import ValueChangeDialog from "../common/ValueChangeDialog";
import MediumButton from "../common/MediumButton";
import OnevocaDialog from "../common/OnevocaDialog";
import { ServerStatus, fetchServerStatus } from "../common/serverStatus";

export default function AccountSettingScreen() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [profileIconSrc, setProfileIconSrc] = useState<string>();

  const [username, setUsername] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [serverStatus, setServerStatus] = useState<ServerStatus>();
  const [updateProfileImageStatus, setUpdateProfileImageStatus] = useState(
    Status.Ready
  );
  const [profileImageDeleteStatus, setProfileImageDeleteStatus] = useState(
    Status.Ready
  );
  const [usernameUpdateStatus, setUsernameUpdateStatus] = useState(
    Status.Ready
  );
  const [userDeleteStatus, setUserDeleteStatus] = useState(Status.Ready);

  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const [openEditUsernameDialog, setOpenEditUsernameDialog] = useState(false);
  const [openProfileImageChangeMenu, setOpenProfileImageChangeMenu] =
    useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const profileImageChangeButtons: BasicMenuButton[] = [
    {
      title: t("ProfileImageUploadButton"),
      handleOnClose: () => {
        setOpenProfileImageChangeMenu(false);
        fileInputRef.current?.click();
      },
    },
    {
      title: t("ProfileImageRemoveButton"),
      handleOnClose: () => {
        setOpenProfileImageChangeMenu(false);
        deleteProfileImage();
      },
    },
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUsername(user.displayName);
        setProfileIconSrc(
          `${profileImageUrl}${
            user.providerData[0].uid
          }.jpg?t=${getLastUpdatedProfileImage()}`
        );
        checkServerInspect();
      } else {
        logout();
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    switch (serverStatus) {
      case ServerStatus.Maintenance:
        navigate("/maintenance");
        break;
      case ServerStatus.Running:
        break;
    }
  }, [serverStatus]);

  useEffect(() => {
    switch (updateProfileImageStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        completeUpdateProfileImage();
        break;
    }
  }, [updateProfileImageStatus]);

  useEffect(() => {
    switch (profileImageDeleteStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        completeUpdateProfileImage();
        break;
    }
  }, [profileImageDeleteStatus]);

  useEffect(() => {
    switch (usernameUpdateStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        completeUpdateUsername();
        break;
    }
  }, [usernameUpdateStatus]);

  useEffect(() => {
    switch (userDeleteStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        completeDeleteUser();
        break;
    }
  }, [userDeleteStatus]);

  async function checkServerInspect() {
    try {
      setServerStatus(await fetchServerStatus());
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  function moveToHome() {
    navigate("/");
  }

  function completeUpdateProfileImage() {
    setToastMessage(t("TermEditedToastMessage"));
    if (auth.currentUser !== null) {
      setLastUpdatedProfileImage(Date.now().toString());
      setProfileIconSrc(undefined);
      setProfileIconSrc(
        `${profileImageUrl}${
          auth.currentUser.providerData[0].uid
        }.jpg?t=${getLastUpdatedProfileImage()}`
      );
    }
  }

  function completeUpdateUsername() {
    setToastMessage(t("TermEditedToastMessage"));
    if (auth.currentUser !== null) {
      setUsername(auth.currentUser.displayName);
    }
  }

  async function completeDeleteUser() {
    await deleteCurrentUser();
    navigate("/login");
  }

  function handleOnClickProfileImageChangeButton(
    e: React.MouseEvent<HTMLDivElement>
  ) {
    setAnchorEl(e.currentTarget);
    setOpenProfileImageChangeMenu(true);
  }

  async function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    const uid = auth.currentUser?.providerData[0].uid;
    if (file === undefined || uid === undefined) {
      return;
    }
    updateProfileImage(await convertFileToJpg(file, uid, 150));
  }

  async function updateProfileImage(image: string) {
    setUpdateProfileImageStatus(Status.Loading);
    try {
      await updateProfileImageFromAPI(image);
      setUpdateProfileImageStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setUpdateProfileImageStatus(Status.Ready);
    }
  }

  async function deleteProfileImage() {
    setProfileImageDeleteStatus(Status.Loading);
    try {
      await deleteProfileImageFromAPI();
      setProfileImageDeleteStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setProfileImageDeleteStatus(Status.Ready);
    }
  }

  async function updateUsername(username: string) {
    setUsernameUpdateStatus(Status.Loading);
    try {
      await updateUsernameFromAPI(username);
      setUsernameUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setUsernameUpdateStatus(Status.Ready);
    }
  }

  async function deleteUser() {
    setUserDeleteStatus(Status.Loading);
    try {
      await deleteUserFromAPI();
      setUserDeleteStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setUserDeleteStatus(Status.Ready);
    }
  }

  return (
    <>
      <div className={styles.stack}>
        <div className={styles.container}>
          <TopNavigation
            handleClickToggleLeftBarButton={() => {}}
            handleClickOnevocaLogo={() => moveToHome()}
            handleClickUserProfileIcon={() => {}}
            handleClickLogOutButton={() => {}}
            handleClickTab={() => {}}
            showTab={false}
            showUserIcon={false}
          />
          <div className={styles.settingsContainer}>
            <div className={styles.userProfileContainer}>
              <p className={styles.titleText}>{t("PersonalInformation")}</p>
              <div className={styles.userProfileContentContainer}>
                <div className={styles.profilePictureContainer}>
                  <p className={styles.titleText}>{t("ProfilePicture")}</p>
                  <ProfileIcon
                    src={profileIconSrc}
                    size="80px"
                    onCursorPoint={false}
                    onClick={() => {}}
                  />
                  <SmallButton
                    title={t("ProfilePictureChangeButton")}
                    style="normal"
                    onClick={handleOnClickProfileImageChangeButton}
                  />
                  <input
                    type="file"
                    accept="image/*,image/heic"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
                <Divider />
                <div className={styles.usernameContainer}>
                  <div className={styles.usernameLeftContainer}>
                    <p className={styles.titleText}>{t("Username")}</p>
                    <p className={styles.valueText}>{username}</p>
                  </div>
                  <div className={styles.usernameRightContainer}>
                    <button
                      className={styles.editButton}
                      onClick={() => {
                        setOpenEditUsernameDialog(true);
                      }}
                    >
                      {t("Edit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.accountAndPrivacyContainer}>
              <p className={styles.titleText}>{t("Account")}</p>
              <div className={styles.accountAndPrivacyCountentContainer}>
                <div className={styles.deleteAccountContainer}>
                  <div className={styles.deleteAccountLeftContainer}>
                    <p className={styles.titleText}>
                      {t("DeleteAccountTitle")}
                    </p>
                    <p className={styles.valueText}>
                      {t("DeleteAccountMessage")}
                    </p>
                  </div>
                  <div className={styles.deleteAcountRightContainer}>
                    <MediumButton
                      title={t("DeleteAccountButton")}
                      style="delete"
                      onClick={() => {
                        setOpenDeleteUserDialog(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BusinessContainer />
        </div>
        <BasicMenu
          isOpen={openProfileImageChangeMenu === true}
          anchorEl={anchorEl}
          buttons={profileImageChangeButtons}
          handleOnClose={() => {
            setOpenProfileImageChangeMenu(false);
          }}
        />
        <ValueChangeDialog
          open={openEditUsernameDialog === true}
          title={t("ChangeUsername")}
          hint={t("NewUsername")}
          minTextCount={1}
          maxTextCount={20}
          onSave={updateUsername}
          onClose={() => {
            setOpenEditUsernameDialog(false);
          }}
        />
        <OnevocaDialog
          open={openDeleteUserDialog === true}
          title={t("DeleteAccountTitle")}
          message={t("DeleteAccountMessage")}
          buttons={[
            {
              title: t("Cancel"),
              style: "normal",
              onClick: () => {
                setOpenDeleteUserDialog(false);
              },
            },
            {
              title: t("Delete"),
              style: "delete",
              onClick: () => {
                setOpenDeleteUserDialog(false);
                deleteUser();
              },
            },
          ]}
        />
        <Snackbar
          open={toastMessage !== null}
          message={toastMessage}
          autoHideDuration={3000}
          onClose={() => {
            setToastMessage(null);
          }}
        />
        {isLoading === true && <LoadingScreen />}
      </div>
    </>
  );
}
