import styles from "./LeftTabBarButton.module.css";
import { ReactComponent as Learn } from "../../learn.svg";
import { ReactComponent as List } from "../../terms.svg";
import classNames from "classnames";

interface LeftTabBarButtonProps {
  title: string;
  type: "list" | "learn";
  selected: boolean;
  onClick: (tab: "list" | "learn") => void;
}

export default function LeftTabBarButton(props: LeftTabBarButtonProps) {
  const containerClass = classNames(styles.container, {
    [styles.selectedContainer]: props.selected === true,
  });

  const iconClass = classNames(styles.icon, {
    [styles.selectedIcon]: props.selected === true,
  });

  const titleTextClass = classNames(styles.titleText, {
    [styles.selectedTitleText]: props.selected === true,
  });

  return (
    <>
      <div
        className={containerClass}
        onClick={() => props.onClick(props.type)}
        onMouseEnter={(e) => {
          if (props.selected === true) {
            return;
          }

          (e.currentTarget as HTMLDivElement).style.backgroundColor =
            "var(--card-color)";
        }}
        onMouseLeave={(e) => {
          (e.currentTarget as HTMLDivElement).style.backgroundColor = "";
        }}
      >
        {props.type === "list" ? (
          <List className={iconClass} />
        ) : (
          <Learn className={iconClass} />
        )}
        <p className={titleTextClass}>{props.title}</p>
      </div>
    </>
  );
}
