import classNames from "classnames";
import styles from "./MediumButton.module.css";

interface MediumButtonProps {
  title: string;
  style: "clear" | "primary" | "delete";
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export default function MediumButton(props: MediumButtonProps) {
  const buttonClass = classNames(styles.button, {
    [styles.styleClear]: props.style === "clear",
    [styles.stylePrimary]: props.style === "primary",
    [styles.styleDelete]: props.style === "delete",
  });

  return (
    <>
      <div className={buttonClass} onClick={props.onClick}>
        {props.title}
      </div>
    </>
  );
}
