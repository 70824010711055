import { useEffect, useState } from "react";
import Term from "./Term";
import { useAppSelector } from "../../hook";
import { selectTerms } from "./termSlice";

export function useTerms() {
  const ts = useAppSelector(selectTerms);
  const [terms, setTerms] = useState<Term[]>([]);

  useEffect(() => {
    // TODO
    setTerms(ts);
    // TODO
  }, [ts]);

  return terms;
}
