import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const termSlice = createSlice({
  name: "termSlice",
  initialState: {
    terms: [] as any[],
  },
  reducers: {
    updateTerms: (state, action: PayloadAction<any>) => {
      state.terms = action.payload;
    },
    updatePartialTerms: (state, action: PayloadAction<any[]>) => {
      const updatedTerms = action.payload;
      updatedTerms.forEach((updatedTerm) => {
        const index = state.terms.findIndex(
          (term) => term.id === updatedTerm.id
        );
        if (index !== -1) {
          state.terms[index] = { ...state.terms[index], ...updatedTerm };
        } else {
          state.terms.push(updatedTerm);
        }
      });
    },
  },
});

export const { updateTerms, updatePartialTerms } = termSlice.actions;
export const selectTerms = (state: RootState) => state.term.terms;
