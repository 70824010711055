import classNames from "classnames";
import styles from "./BigButton.module.css";

interface BigButtonProps {
  title: string;
  style: "normal" | "disable" | "special" | "clear";
  hoverColor?: string;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

function BigButton(props: BigButtonProps) {
  const buttonClass = classNames(styles.bigButton, {
    [styles.styleNormal]: props.style === "normal",
    [styles.styleDisable]: props.style === "disable",
    [styles.styleSpecial]: props.style === "special",
    [styles.styleClear]: props.style === "clear",
  });
  return (
    <>
      <div
        className={buttonClass}
        onClick={props.handleClick}
        onMouseEnter={(e) => {
          if (props.hoverColor === undefined) {
            return;
          }
          (e.currentTarget as HTMLDivElement).style.borderColor = "#FFFFFF";
          (e.currentTarget as HTMLDivElement).style.color = "#FFFFFF";
          (e.currentTarget as HTMLDivElement).style.backgroundColor =
            props.hoverColor || "";
        }}
        onMouseLeave={(e) => {
          if (props.hoverColor === undefined) {
            return;
          }
          (e.currentTarget as HTMLDivElement).style.borderColor = "";
          (e.currentTarget as HTMLDivElement).style.color = "";
          (e.currentTarget as HTMLDivElement).style.backgroundColor = "";
        }}
      >
        <p className={styles.title}>{props.title}</p>
      </div>
    </>
  );
}

export default BigButton;
