import { Menu, MenuItem } from "@mui/material";

interface BasicMenuProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  buttons: BasicMenuButton[];
  handleOnClose: () => void;
}

export interface BasicMenuButton {
  title: string;
  handleOnClose: () => void;
}

export default function BasicMenu(props: BasicMenuProps) {
  return (
    <Menu
      open={props.isOpen}
      anchorEl={props.anchorEl}
      onClose={() => props.handleOnClose()}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      autoFocus={false}
      sx={{
        mt: 1,
      }}
    >
      {props.buttons.map((button, index) => (
        <MenuItem key={index} onClick={() => button.handleOnClose()}>
          {button.title}
        </MenuItem>
      ))}
    </Menu>
  );
}
