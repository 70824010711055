import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TermAddField.module.css";
import PlaceHolderTextField from "../common/PlaceHolderTextField";
import Term, {
  definitionMaxSize,
  memoMaxSize,
  pronunciationMaxSize,
  termMaxSize,
} from "./Term";
import { RemoveCircle } from "@mui/icons-material";

interface TermAddFieldProps {
  page: number;
  term: Term;
  showRemoveButton?: boolean;
  onChangeTerm: (term: string) => void;
  onChangeDefinition: (definition: string) => void;
  onChangeMemo: (memo: string) => void;
  onChangePronunciation: (pronunciation: string) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onClickRemoveButton: () => void;
  inputRef: (el: HTMLTextAreaElement | null) => void;
  firstInputRef: (el: HTMLTextAreaElement | null) => void;
}

export default function TermAddField(props: TermAddFieldProps) {
  const { t } = useTranslation();

  const [word, setWord] = useState("");
  const [definition, setDefinition] = useState("");
  const [memo, setMemo] = useState("");
  const [pronunciation, setPronunciation] = useState("");

  const [onGlobalFocus, setOnGlobalFocus] = useState(false);
  const [wordOnFocus, setWordOnFocus] = useState(false);
  const [definitionOnFocus, setDefinitionOnFocus] = useState(false);
  const [memoOnFocus, setMemoOnFocus] = useState(false);
  const [pronunciationOnFocus, setPronunciationOnFocus] = useState(false);

  useEffect(() => {
    onChangeWord(props.term.term);
    onChangeDefinition(props.term.definition);
    onChangeMemo(props.term.memo);
    onChangePronunciation(props.term.pronunciation);
  }, [props.term]);

  useEffect(() => {
    props.onChangeTerm(word);
  }, [word]);

  useEffect(() => {
    props.onChangeDefinition(definition);
  }, [definition]);

  useEffect(() => {
    props.onChangeMemo(memo);
  }, [memo]);

  useEffect(() => {
    props.onChangePronunciation(pronunciation);
  }, [pronunciation]);

  useEffect(() => {
    setOnGlobalFocus(
      wordOnFocus === true ||
        definitionOnFocus === true ||
        memoOnFocus === true ||
        pronunciationOnFocus === true
    );
  }, [wordOnFocus, definitionOnFocus, memoOnFocus, pronunciationOnFocus]);

  function onChangeWord(value: string) {
    setWord(value);
  }

  function onChangeDefinition(value: string) {
    setDefinition(value);
  }

  function onChangeMemo(value: string) {
    setMemo(value);
  }

  function onChangePronunciation(value: string) {
    setPronunciation(value);
  }

  function onFocusChangeWord(onFocus: boolean) {
    setWordOnFocus(onFocus);
  }

  function onFocusChangeDefinition(onFocus: boolean) {
    setDefinitionOnFocus(onFocus);
  }

  function onFocusChangeMemo(onFocus: boolean) {
    setMemoOnFocus(onFocus);
  }

  function onFocusChangePronunciation(onFocus: boolean) {
    setPronunciationOnFocus(onFocus);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <p className={styles.pageText}>{props.page}</p>
          {props.showRemoveButton !== undefined &&
            props.showRemoveButton === true && (
              <div
                className={styles.removeButtonContainer}
                onClick={() => props.onClickRemoveButton()}
              >
                <RemoveCircle
                  style={{ color: "var(--disabled-color)", fontSize: 24 }}
                />
              </div>
            )}
        </div>
        <PlaceHolderTextField
          text={word}
          hint={t("Word")}
          minCount={1}
          maxCount={termMaxSize}
          lineStyle="multiple"
          handleTextChange={onChangeWord}
          textAreaRef={props.firstInputRef}
          onChangeFocus={onFocusChangeWord}
          onGlobalFocus={onGlobalFocus}
        />
        <PlaceHolderTextField
          text={definition}
          hint={t("Meaning")}
          minCount={1}
          maxCount={definitionMaxSize}
          lineStyle="multiple"
          handleTextChange={onChangeDefinition}
          onChangeFocus={onFocusChangeDefinition}
          onGlobalFocus={onGlobalFocus}
        />
        <PlaceHolderTextField
          text={memo}
          hint={`${t("Description")} (${t("Optional")})`}
          maxCount={memoMaxSize}
          lineStyle="multiple"
          handleTextChange={onChangeMemo}
          onChangeFocus={onFocusChangeMemo}
          onGlobalFocus={onGlobalFocus}
        />
        <PlaceHolderTextField
          text={pronunciation}
          hint={`${t("Pronunciation")} (${t("Optional")})`}
          maxCount={pronunciationMaxSize}
          lineStyle="multiple"
          handleTextChange={onChangePronunciation}
          textAreaRef={props.inputRef}
          onKeyDownTextArea={props.onKeyDown}
          onChangeFocus={onFocusChangePronunciation}
          onGlobalFocus={onGlobalFocus}
        />
      </div>
    </>
  );
}
